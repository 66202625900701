<template>
  <span>logged out</span>
</template>

<script>
export default {
  name: 'Logout',
  mounted() {
    this.$store.dispatch('logout').then(() => {
      this.$router.replace('/login')
    })
  }
}
</script>
