<template>
  <v-card
    class="mx-auto my-2"
    :loading="importingState.upload && importingState.upload.successful === null"
  >
    <v-card-text>
      <div>
        <span class="mr-5">{{ importingState.loading?.filename || importingState.id }}</span>
        <span class="float-end">
          <v-tooltip v-if="timestamp" bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-icon>mdi-calendar-clock</v-icon>
                {{ getRelativeTime(timestamp) }}
              </span>
            </template>
            <span>{{ timestamp.toLocaleString('de', { dateStyle: 'full', timeStyle: 'medium' }) }}</span>
          </v-tooltip>
          <span class="ml-3">
            <v-icon>mdi-account</v-icon>
            <span v-if="accounts[importingState.submitter]">
              {{ accounts[importingState.submitter].first_name }}
              {{ accounts[importingState.submitter].last_name }}
            </span>
            <span v-else>
              {{ importingState.submitter }}
            </span>
          </span>
          <span class="ml-3">
            <v-icon>mdi-table</v-icon>
            {{ importingState.collection }}
          </span>
        </span>
      </div>
      <div class="mt-3" style="clear: both;">
        <v-stepper height="auto" flat>
          <v-stepper-header style="height: 30px;">
            <template
              v-for="step in steps"
            >
              <v-menu
                :key="step.slug"
                v-model="step.menu"
                :close-on-content-click="false"
                location="end"
              >
                <template v-slot:activator="{ props }">
                  <v-stepper-step
                    v-if="(!!importingState[step.slug] && (importingState[step.slug].successful === true || importingState[step.slug].successful === false)) || !importingState[step.slug]"
                    step=""
                    :complete="!!importingState[step.slug]"
                    :class="{'py-0': true, 'clickable': !!importingState[step.slug] }"
                    :rules="[() => !importingState[step.slug] || importingState[step.slug].successful === true]"
                    :complete-icon="step.icon"
                    v-bind="props"
                    @click.stop.prevent="step.menu = !!importingState[step.slug] && !step.menu"
                  >
                    {{ step.labelShort }}
                    <small v-if="importingState[step.slug]?.errors">
                      {{ Array.isArray(importingState[step.slug].errors) ? importingState[step.slug].errors.length : importingState[step.slug].errors }} Fehler
                    </small>
                  </v-stepper-step>
                  <v-stepper-step
                    v-else
                    step=""
                    :complete="true"
                    :complete-icon="step.icon"
                    class="py-0"
                  >
                    <v-progress-circular
                      :indeterminate="true"
                      :size="24"
                    />
                  </v-stepper-step>
                </template>

                <v-card min-width="300">
                  <v-card-text>
                    <v-simple-table v-if="step.menu" class="mb-2">
                      <tbody>
                        <tr
                          v-for="attr in stepAttributes(importingState[step.slug])"
                          :key="attr.key"
                        >
                          <td>{{ attr.key }}</td>
                          <td class="font-weight-bold">
                            <div v-html="attr.val" />
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>

                    <div v-if="step.menu && importingState[step.slug].errors">
                      <v-alert
                        v-for="(error, ei) in collapseErrors(importingState[step.slug])"
                        :key="ei"
                        class="mb-2"
                        dense
                        type="error"
                      >
                        <div v-if="error.loc.length <= 5" style="font-weight: bold;">
                          <div v-for="(loc, li) in error.loc" :key="li">
                            {{ Array.isArray(loc) ? loc.join(' → ') : loc }}
                          </div>
                        </div>
                        <div v-else>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <b
                                v-bind="attrs"
                                v-on="on"
                              >An {{ error.loc.length }} Stellen</b>
                            </template>
                            <div v-for="(loc, li) in error.loc" :key="li">
                              {{ Array.isArray(loc) ? loc.join(' → ') : loc }}
                            </div>
                          </v-tooltip>
                        </div>
                        <div>{{ error.msg }} ({{ error.type }})</div>
                        <div v-if="error.ctx">
                          Kontext:
                          <span
                            v-for="(val, key) in error.ctx"
                            :key="key"
                            class="ml-2"
                          >({{ key }}={{ val }})</span>
                        </div>
                      </v-alert>
                    </div>
                  </v-card-text>
                </v-card>
              </v-menu>
              <v-divider v-if="step.slug !== 'writing'" :key="step.slug + '.divider'" />
            </template>
          </v-stepper-header>
        </v-stepper>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
/**
 * https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
function humanFileSize(bytes, si=false, dp=1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10**dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


  return bytes.toFixed(dp) + ' ' + units[u];
}

function timeConversion(secs) {
  var millisec = (secs * 1000).toFixed(2)
  var seconds = secs.toFixed(2)
  var minutes = (secs / 60).toFixed(1)
  var hours = (secs / (60 * 60)).toFixed(1)
  var days = (secs / (60 * 60 * 24)).toFixed(1)

  if (millisec < 500) {
    return millisec + ' ms'
  } else if (seconds < 60) {
      return seconds + " sec"
  } else if (minutes < 60) {
      return minutes + " min"
  } else if (hours < 24) {
      return hours + " hrs"
  } else {
      return days + " days"
  }
}

export default {
  name: "ImportingState",
  props: {
    importingState: Object,
    accounts: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    timeUnits: {
      year  : 24 * 60 * 60 * 1000 * 365,
      month : 24 * 60 * 60 * 1000 * 365/12,
      day   : 24 * 60 * 60 * 1000,
      hour  : 60 * 60 * 1000,
      minute: 60 * 1000,
      second: 1000
    },
    rtf: new Intl.RelativeTimeFormat('de', { numeric: 'auto' }),
    steps: [{
      slug: 'loading',
      labelLong: 'Datei öffnen',
      labelShort: 'Öffnen',
      icon: 'mdi-folder-open',
      menu: false,
    }, {
      slug: 'parsing',
      labelLong: 'Datei analysieren',
      labelShort: 'Analysieren',
      icon: 'mdi-table-eye',
      menu: false,
    }, {
      slug: 'merging',
      labelLong: 'Einträge mit Datenbank abgleichen',
      labelShort: 'Abgleichen',
      icon: 'mdi-table-merge-cells',
      menu: false,
    }, {
      slug: 'writing',
      labelLong: 'Einträge in Datenbank schreiben',
      labelShort: 'Speichern',
      icon: 'mdi-database-arrow-down',
      menu: false,
    }],
  }),
  computed: {
    timestamp() {
      if (this.importingState.start_time) {
        return new Date(this.importingState.start_time)
      }
      return null
    },
  },
  methods: {
    stepAttributes(step) {
      const noDisplay = ['errors', 'successful', 'start_time', 'error_types']
      const attributes = []
      if (step) {
        Object.keys(step)
            .filter(k => noDisplay.indexOf(k) === -1 && step[k] !== null)
            .forEach(k => {
              let val = step[k]
              if (k === 'size') {
                val = humanFileSize(val)
              } else if (k === 'duration') {
                val = timeConversion(val)
              } else if (k === 'data') {
                if (val[0] === 'json') {
                  val = '<pre>' + JSON.stringify(JSON.parse(val[1]), null, 4) + '</pre>'
                } else {
                  val = '<a download href="' + this.$store.state.apiPath + '/patsurvey/download/' + this.importingState.id + '">download</a>'
                }
              }
              attributes.push({
                key: k,
                val: val,
              })
            })
      }
      return attributes
    },
    collapseErrors(step) {
      const errors = []
      const errorMap = {}
      if (step && step.errors) {
        step.errors.forEach(e => {
          const key = '' + e.type + e.msg + JSON.stringify(e.ctx)
          let errorColl = errorMap[key]
          if (!errorColl) {
            errorColl = {
              type: e.type,
              msg: e.msg,
              ctx: e.ctx,
              loc: [],
            }
            errorMap[key] = errorColl
            errors.push(errorColl)
          }
          errorColl.loc.push(e.loc)
        })
      }
      return errors
    },
    getRelativeTime(d1, d2 = new Date()) {
      var elapsed = d1 - d2

      // "Math.abs" accounts for both "past" & "future" scenarios
      for (var u in this.timeUnits)
        if (Math.abs(elapsed) > this.timeUnits[u] || u === 'second')
          return this.rtf.format(Math.round(elapsed/this.timeUnits[u]), u)
    }
  }
}
</script>

<style scoped lang="scss">
.clickable {
  cursor: pointer;
  &:hover {
    background: rgba(0,0,0,.06);
  }
}
</style>