<template>
  <div>
    <div style="display: flex; flex-direction: row;" class="my-4">
      <div
        v-for="(query, qi) in queries"
        :key="qi"
        class="mx-1"
      >
        <v-text-field
          v-if="query.type === 'text'"
          outlined
          hide-details
          dense
          v-bind="query.props"
          @change="val => updateQueryVal(query.key, val)"
          @input="val => updateQueryValDebounced(query.key, val)"
        />

        <v-select
          v-else-if="query.type === 'select'"
          outlined
          hide-details
          dense
          v-bind="query.props"
          @change="val => updateQueryVal(query.key, val)"
        />

        <v-alert
          v-else
          dense
          type="error"
        >
          Query type <strong>{{ query.type }}</strong> not implemented.
        </v-alert>
      </div>
    </div>

    <v-pagination
      v-if="total > 0"
      v-model="page"
      :length="totalPages"
      :disabled="pending"
      :total-visible="12"
    />
    <Pending
      v-model="promise"
      v-slot="{ result: result }"
    >
      <div v-if="total === 0" class="msg py-8">Nichts gefunden<br>¯\_(ツ)_/¯</div>
      <div v-else>
        <slot :page="result.data" />
        <v-pagination
          v-model="page"
          :length="totalPages"
          :disabled="pending"
          :total-visible="12"
        />
      </div>
    </Pending>
  </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'

export default {
  name: 'Pagination',
  props: {
    endpoint: String,
    pageSize: {
      type: Number,
      default: 10,
    },
    queries: {
      type: Array,
      default: () => {return []},
    }
  },
  data: () => ({
    promise: null,
    page: 1,
    total: 0,
    totalPages: null,
    pending: false,
    queryVals: {},
    updateQueryValDebounced: null,
  }),
  watch: {
    page(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetch(newVal)
      }
    }
  },
  mounted() {
    this.fetch(1)
    this.updateQueryValDebounced = _.debounce(this.updateQueryVal, 700)
  },
  methods: {
    updateQueryVal(key, val) {
      if (typeof val === 'string' || val instanceof String)  val = val.trim()
      if (val === '' || val === null) val = undefined
      if (val !== this.queryVals[key]) {
        this.$set(this.queryVals, key, val)
        this.fetch(1)
      }
    },
    fetch(page) {
      if (!this.pending) {
        this.pending = true
        const promise = this.promise = new Promise((resolve, reject) => {
          axios.get(
            this.endpoint,{
              params: {
                ...this.queryVals,
                skip: this.pageSize * (page - 1),
                limit: this.pageSize,
              }
            },
          )
            .then(response => {
              if (promise === this.promise) {
                this.page = response.data.page + 1
                this.pageSize = response.data.limit
                this.totalPages = response.data.totalPages
                this.total = response.data.total
              }
              resolve(response.data)
            }, reject)
            .finally(() => {
              if (promise === this.promise) {
                this.pending = false
              }
            })
        })
      }
    },
  },
}
</script>

<style scoped>
.msg {
    text-align: center;
    text-transform: uppercase;
    font-size: 1.3em;
    opacity: .7;
}
</style>