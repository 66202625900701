<template>
  <div>
    <div class="greeter">
      <v-container class="mb-12">
        <v-row>
          <v-col
            style="display: flex;"
            class="mt-12"
            :xl="logosize"
            :md="logosize+1"
            :sm="logosize+2"
            :cols="logosize+3"
            offset-xl="2"
            offset="1"
          >
            <v-img
              v-observe-visibility="{
                callback: visibilityChanged,
                intersection: {
                  threshold: 0.4,
                },
              }"
              :src="require('../assets/gfqg-logo-caption-path-primary.svg')"
              contain
              eager
            />
            <v-img
              :src="require('../assets/StandwithUkraine2.jpg')"
              contain
              eager
              height="180px"
            />
          </v-col>
        </v-row>
        <v-row class="pt-12">
          <v-col cols="12">
            <h1>Unsere Expertise</h1>
          </v-col>
          <v-col
            v-for="childRoute in $store.state.routesById['aeaac26f-640c-4f11-8ec2-f97aa7ddf881'].children"
            :key="childRoute.id"
            lg="4"
            md="6"
            cols="12"
          >
            <v-hover v-slot:default="{ hover }">
              <v-card
                height="100%"
                :to="childRoute.link"
                :elevation="hover ? 8 : 2"
              >
                <v-card-title>{{ childRoute.title }}</v-card-title>
                <v-card-subtitle v-if="childRoute.subtitle">{{ childRoute.subtitle }}</v-card-subtitle>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div class="greeter-inv">
      <v-container>
        <v-row class="my-12 text-center">
          <v-col cols="12">
            <h1>Wieso wir?</h1>
          </v-col>
          <v-col
            v-for="(bulletPoint, index) in website.bulletpoints"
            :key="index"
            md="4"
            cols="12"
          >
            <v-card
              height="100%"
              flat
              :to="bulletPoint.link"
            >
              <v-card-text>
                <v-icon x-large style="color:#023465;">{{ bulletPoint.icon }}</v-icon>
              </v-card-text>
              <v-card-title>
                <h3 style="width:100%">{{ bulletPoint.title }}</h3>
              </v-card-title>
              <v-card-text>
                {{ bulletPoint.text }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>


        <Pending
          v-slot="{ result: staff }"
          v-observe-visibility="{
            callback: loadStaff,
            intersection: {
              threshold: 0.4,
            },
          }"
          :value="staffPromise"
        >
          <v-row justify="center">
            <v-col cols="12" class="text-center">
              <h1>Wer sind wir?</h1>
            </v-col>

            <v-col
              v-for="member in staff"
              :key="member.id"
              md="3"
              sm="6"
              cols="12"
              class="text-center"
            >
              <a @click="selectedMember = member; showMemberDialog = true">
                <v-avatar
                  size="200"
                  class="mb-4"
                  :color="member.picture ? '': 'primary'"
                >
                  <img
                    v-if="member.picture"
                    :src="member.picture + '?fit=cover&width=200&height=200&quality=80'"
                    :alt="member.name"
                  >
                  <v-icon
                    v-else
                    :size="100"
                    color="white"
                  >
                    mdi-account
                  </v-icon>
                </v-avatar>
                <div class="leader-name">{{ member.title }} {{ member.first_name }} {{ member.last_name }}</div>
                <div class="caption">{{ member.role }}</div>
              </a>
            </v-col>

            <v-dialog
              v-model="showMemberDialog"
              max-width="700px"
              style="position:relative;"
              scrollable
            >
              <v-card
                v-if="selectedMember"
                class="mx-auto"
                tile
              >
                <v-toolbar class="px-0" dense flat>
                  <v-spacer />
                  <v-btn icon @click="showMemberDialog = false" tile>
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <PersonView :person="selectedMember" padding="8" max-height="90vh" />
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-row>
        </Pending>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PersonView from '@/components/PersonView'
import axios from 'axios'

export default {
  name: 'Home',
  components: {  PersonView, },
  data: () => ({
    selectedMember: null,
    staffPromise: null,
    showMemberDialog: false,
  }),

  computed: {
    ...mapState(['website']),
    logosize() {
      return this.$route.query.logo ? parseInt(this.$route.query.logo) : 3
    }
  },
  beforeDestroy() {
    this.$store.commit('showAppBarLogo')
  },
  methods: {
    loadStaff(isVisible) {
      if (isVisible && !this.staffPromise) {
        this.staffPromise = new Promise((resolve, reject) => {
              axios.get('/c2/items/staff?fields=*,picture.id,picture.filename_download').then(response => {
                  resolve(response.data.data.map(p => {
                    if (p.picture) {
                      let picUrl = '/c2/assets/' + p.picture.id
                      if (p.picture.filename_download) picUrl += '/' + p.picture.filename_download
                      p.picture = picUrl
                    }
                    return p
                  }))
              }, reject)
          })
        }
    },
    visibilityChanged(isVisible) {
      this.$store.commit('hideAppBarLogo', isVisible)
    },
  },
};
</script>

<style lang="scss">
.greeter {
  background-image: url(../assets/bg23.png) !important;
  // background-color: aqua;
  background-repeat: no-repeat;
  background-size: 100% 95%;
  background-position: bottom;
  margin-top: -64px;
  // margin-bottom: 128px;
  padding-top: 128px;
  height: 100vh;
}
.greeter-inv{
  background-image: url(../assets/bg.png) !important;
  // background-color: aqua;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: top;
  // margin-top: -64px;
  // margin-bottom: 128px;
  padding-top: 96px;
  // height: 100vh;
}

.leader-name {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  line-height: 2rem;
}
</style>
