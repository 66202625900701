<template>
  <v-container>
    <h1>Umfrage Zugangscodes</h1>
    <v-row>
      <v-col cols="12">
        <v-expansion-panels v-model="panel" focusable>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h3>Neue Zugangscodes generieren</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <Pending v-model="scopePromise">
                <h3 class="mt-4">Welcher Fragebogen soll abgefragt werden?</h3>
                <v-row>
                  <v-col cols="12">
                    <v-select
                      v-model="selectedSurvey"
                      :disabled="pending"
                      :items="surveys"
                      label="Fragebogen"
                      item-text="title"
                      item-value="id"
                    />
                  </v-col>
                </v-row>

                <div v-if="selectedSurvey">
                  <h3>In welchen Kliniken/Abteilungen sollen die Zugangscodes ausgegeben werden?</h3>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-select
                        v-model="selectedClients"
                        :disabled="pending"
                        :items="availableClients"
                        item-text="label"
                        item-value="id"
                        label="Klinik"
                        :multiple="true"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-select
                        v-model="selectedIndications"
                        :disabled="pending"
                        :items="availableIndications"
                        item-text="name_long"
                        item-value="id"
                        label="Abteilungen"
                        :multiple="true"
                      />
                    </v-col>
                  </v-row>
                  <h3>In welchem Zeitraum sollen sie Zugangscodes ausgegeben werden?</h3>
                  <p>
                    Optionale Angaben um die Auswahl des Entlasszeitpunkts einzuschränken.
                    Standardmäßig stehen der aktuelle und die beiden vorangegangenen Monate zur Auswahl.
                  </p>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-menu
                        ref="minMenu"
                        v-model="minMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="selectedMinMonth"
                            label="Frühestes Entlassdatum"
                            prepend-icon="mdi-calendar-start"
                            readonly
                            clearable
                            v-bind="attrs"
                            v-on="on"
                          />
                        </template>
                        <v-card color="primary">
                          <v-date-picker
                            v-model="selectedMinMonth"
                            type="month"
                            locale="de"
                            no-title
                            scrollable
                            color="primary"
                            header-color="primary"
                            :elevation="0"
                          />
                          <v-card-actions>
                            <v-spacer />
                            <v-btn
                              color="primary"
                              tile
                              :elevation="0"
                              @click="$refs.minMenu.save(selectedMinMonth)"
                            >
                              OK
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-menu
                        ref="maxMenu"
                        v-model="maxMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="selectedMaxMonth"
                            label="Spätestes Entlassdatum"
                            prepend-icon="mdi-calendar-end"
                            readonly
                            clearable
                            v-bind="attrs"
                            v-on="on"
                          />
                        </template>
                        <v-card color="primary">
                          <v-date-picker
                            v-model="selectedMaxMonth"
                            type="month"
                            locale="de"
                            no-title
                            scrollable
                            color="primary"
                            header-color="primary"
                          />
                          <v-card-actions>
                            <v-spacer />
                            <v-btn
                              color="primary"
                              tile
                              :elevation="0"
                              @click="$refs.maxMenu.save(selectedMaxMonth)"
                            >
                              OK
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-menu>
                    </v-col>
                  </v-row>

                  <h3>Wie viele Tage soll der Patient Zeit haben die Umfrage zu bearbeiten, sobald er begonnen hat?</h3>
                  <v-row>
                    <v-col cols="12">
                      <v-slider
                        v-model="selectedTimespan"
                        :disabled="pending"
                        label="Tage"
                        thumb-label="always"
                        min="1"
                        max="60"
                        class="mt-8"
                      />
                    </v-col>
                  </v-row>

                  <h3>Wie viele Zugangscodes sollen generiert werden?</h3>
                  <v-row>
                    <v-col cols="12">
                      <v-slider
                        v-model="selectedCount"
                        :disabled="pending"
                        label="Anzahl"
                        thumb-label="always"
                        min="1"
                        max="500"
                        class="mt-8"
                      />
                    </v-col>
                  </v-row>
                  <v-card-actions>
                    <v-btn
                      block
                      color="primary"
                      :loading="pending"
                      :disabled="pending || selectedClients.length === 0 || selectedIndications.length === 0 || !selectedSurvey"
                      @click="submit"
                    >
                      Zugangscodes generieren
                    </v-btn>
                  </v-card-actions>
                </div>
              </Pending>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <Pending v-model="batchesPromise">
      <v-row>
        <v-col
          v-for="batch in batches"
          :key="batch.id"
          cols="12"
          md="6"
        >
          <SurveyTokenBatchCard :batch="batch" />
        </v-col>
      </v-row>
    </Pending>
  </v-container>
</template>

<script>
import axios from 'axios'
import SurveyTokenBatchCard from '@/components/SurveyTokenBatchCard'

export default {
  name: 'SurveyTokenGeneration',
  components: {
    SurveyTokenBatchCard
  },
  data: () => ({
    panel: null,
    pending: false,
    scopePromise: null,
    batchesPromise: null,

    batches: [],

    surveys: [],
    clients: [],

    surveysById: {},
    clientsById: {},
    indicationsById: {},
    accountsById: {},

    selectedSurvey: null,
    selectedTimespan: 7,
    selectedClients: [],
    selectedIndications: [],
    selectedCount: 100,
    selectedMinMonth: null,
    selectedMaxMonth: null,

    minMenu: false,
    maxMenu: false,
  }),
  computed: {
    availableIndications() {
      let indicationIds = []
      for (let i = 0; i < this.selectedClients.length; i++) {
        const client = this.clientsById[this.selectedClients[i]]
        if (i === 0) {
          indicationIds = [...client.indicationIds]
        } else {
          indicationIds = indicationIds.filter(id => client.indicationIds.includes(id))
        }
      }
      const indications = []
      indicationIds.forEach(id => {
        indications.push(this.indicationsById[id])
      })
      return indications
    },
    availableClients() {
      const clients = []
      if (this.selectedSurvey) {
        const availableIds = this.surveysById[this.selectedSurvey].clients
        console.log(this.surveysById[this.selectedSurvey])
        this.clients.forEach(client => {
          if (availableIds.includes(client.id)) {
            clients.push(client)
          }
        })
      }
      console.log(clients)
      return clients
    },
  },
  watch: {
    availableIndications(val) {
      const availableIndicationIds = []
      val.forEach(indication => {
        availableIndicationIds.push(indication.id)
      })
      const c = [...this.selectedIndications]
      c.forEach(id => {
        if (!availableIndicationIds.includes(id)) {
          console.log(id)
          this.selectedIndications.splice(this.selectedIndications.indexOf(id), 1)
        }
      })
    },
  },
  created() {
    this.batchesPromise = axios.get(this.$store.state.apiPath + '/survey/batches')
    this.batchesPromise.then(response => {
        this.mapById(response.data.surveys, this.surveysById)
        this.mapById(response.data.clients, this.clientsById)
        this.mapById(response.data.indications, this.indicationsById)
        this.mapById(response.data.accounts, this.accountsById)
        response.data.batches.forEach(this.parseBatch)
        this.batches = response.data.batches
      })

    this.scopePromise = axios.get(this.$store.state.apiPath + '/survey/scope')
    this.scopePromise.then(response => {
        this.clients = response.data.clients
        this.clients.forEach(client => {
          this.clientsById[client.id] = client
          client.indicationIds = []
          client.indications.forEach(indication => {
            this.indicationsById[indication.id] = indication
            client.indicationIds.push(indication.id)
          })
        })
        this.surveys = response.data.surveys
        this.mapById(this.surveys, this.surveysById)
      })
  },
  methods: {
    submit() {
      this.pending = true
          axios
              .post(this.$store.state.apiPath + '/survey/batches', {
                survey: this.selectedSurvey,
                clients: this.selectedClients,
                indications: this.selectedIndications,
                count: this.selectedCount,
                from_month: this.selectedMinMonth,
                until_month: this.selectedMaxMonth,
                access_timespan_days: this.selectedTimespan,
              })
              .then(response => {
                this.batches.unshift(this.parseBatch(response.data))
                this.panel = null
              })
              .finally(() => {this.pending = false})
    },
    parseBatch(batch) {
      batch.survey = this.surveysById[batch.survey]
      batch.created_by = this.accountsById[batch.created_by]
      batch.indications = this.resolveIds(batch.indications, this.indicationsById)
      batch.clients = this.resolveIds(batch.clients, this.clientsById)
      batch.created = new Date(batch.created)
      return batch
    },
    mapById(arr, map) {
      arr.forEach(o => {
        if (!map[o.id]) map[o.id] = o
      })
    },
    resolveIds(arr, map) {
      const res = []
      arr.forEach(id => {
        res.push(map[id])
      })
      return res
    },
  }
}
</script>

<style scoped>

</style>