<template>
  <div>
    <h2>Tabelle hochladen</h2>

    <FileUploadArea @file="handleFile" />

    <ImportingState
      v-for="file in files"
      :key="file.index"
      :importing-state="file.result"
      :accounts="accountsById"
    />
    <h2 class="mt-5">Vergangene Uploads</h2>
    <Pagination
      v-slot="{ page: items }"
      :endpoint="$store.state.apiPath + '/patsurvey/history'"
      :queries="queries"
    >
      <ImportingState
        v-for="importItem in items"
        :key="importItem.id"
        :importing-state="importItem"
        :accounts="accountsById"
      />
    </Pagination>
  </div>
</template>

<script>
import axios from 'axios'
import ImportingState from "@/components/ImportingState.vue";
import Pagination from "@/components/Pagination.vue";
import FileUploadArea from "@/components/FileUploadArea.vue";

export function isObject(item) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

export default {
  name: 'SpreadsheetUpload',
  components: {FileUploadArea, Pagination, ImportingState},
  data: () => ({
    files: [],
    accounts: [],
    queries: [
      {
        type: 'text',
        key: 'filename',
        props: {label: 'Dateiname', 'prepend-inner-icon': 'mdi-magnify'},
      }, {
        type: 'select',
        key: 'submitter',
        props: {
          label: 'Hochgeladen von',
          'prepend-inner-icon': 'mdi-account',
          value: null,
          items: [],
          'item-text': a => (a.first_name + ' ' + a.last_name),
          'item-value': 'id',
        },
      }, {
        type: 'select',
        key: 'successful',
        props: {
          label: 'Ergebnis',
          'prepend-inner-icon': 'mdi-check',
          value: null,
          items: [{text: 'Alle', value: null}, {text: 'Nur erfolgreich Importierte', value: 'true'}, {text: 'Nur Fehlgeschlagene', value: 'false'}],
        },
      },
    ],
  }),
  computed: {
    accountsById() {
      const accountsById = {}
      this.accounts.forEach(a => accountsById[a.id] = a)
      return accountsById
    }
  },
  mounted() {
    if (this.$store.state.isManager) {
      this.queries[1].props.value = null
    } else {
      this.queries[1].props.value = this.$store.state.account.id
    }
    axios.get( this.$store.state.apiPath + '/patsurvey/history/accounts').then(
      resp => {
        this.$set(this, 'accounts', resp.data)
        let sel = resp.data
        if (this.$store.state.isManager) {
          sel = [{id: null, first_name: 'Alle', last_name: 'Accounts'}, {divider: true}, {header: 'Nur Uploads von...'}, ...sel]
        }
        this.$set(this.queries[1].props, 'items', sel)
      }
    )
  },
  methods: {
    mergeDeep(target, ...sources) {
      if (!sources.length) return target;
      const source = sources.shift();

      if (isObject(target) && isObject(source)) {
        for (const key in source) {
          if (isObject(source[key])) {
            if (!target[key]) this.$set(target, key, {})
            this.mergeDeep(target[key], source[key]);
          } else {
            this.$set(target, key, source[key])
          }
        }
      }

      return this.mergeDeep(target, ...sources);
    },
    handleFile(jsFile) {
      this.isOver = false
      const file = {
        index: this.files.length,
        name: jsFile.name,
        state: 'pending',
        step: 'upload',
        result: {
          upload: {
            successful: null,
            progress: null,
            errors: null,
          },
          loading: null,
          parsing: null,
          merging: null,
          writing: null,
        },
      }
      this.files.unshift(file)
      // eslint-disable-next-line no-undef
      let formData = new FormData()
      formData.append('file', jsFile)

      const self = this

      let handledBodyLen = 0
      function handleStatusUpdate(msg, logError) {
        try {
          // console.log('status update', msg)
          self.mergeDeep(file.result, JSON.parse(msg))
          handledBodyLen += msg.length
        } catch (e) {
          if (logError) console.error(e)
        }
      }

      function handleResponseUpdate(body, finished) {
        if (body.length > handledBodyLen) {
          // upload step finished if response is coming in
          if (file.step === 'upload') {
            file.result.upload.successful = true
            file.result.upload.progress = 100
          }

          // parse response body
          const newBody = body.substring(handledBodyLen)
          const parts = newBody.split('}{')
          for (let i = 1; i < parts.length; i++) parts[i] = '{' + parts[i]
          for (let i = 0; i < parts.length - 1; i++) {
            parts[i] += '}'
            handleStatusUpdate(parts[i], true)
          }
          handleStatusUpdate(parts[parts.length - 1], finished)
        }
      }

      axios.post( this.$store.state.apiPath + '/patsurvey/',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: progressEvent => {
              file.result.upload.progress = 100 * (progressEvent.loaded / progressEvent.total)
            },
            onDownloadProgress: progressEvent => {
              // console.log(progressEvent)
              handleResponseUpdate(progressEvent.target.response, false)
            },
          }
      ).then(
          response => {
            // console.log(response.data)
            handleResponseUpdate(response.data, true)
            file.state = file.result.successful ? 'success' : 'error'
          },
          error => {
            file[file.step].state = 'error'
            file[file.step].info = error.response.data
            file.state = 'error'
          }
      )
    },
  },
}
</script>

<style scoped lang="scss">

</style>