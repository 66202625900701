import RouteView from '@/components/RouteView'
import Login from '@/components/Login'
import Reports from '@/components/Reports'
import Logout from '@/components/Logout'
import Discharges from '@/components/Discharges'
import Home from '@/components/Home'
import GreetingRouteView from '@/components/GreetingRouteView'
import PasswordReset from '@/components/PasswordReset'
import Profile from '@/components/Profile'
import SpreadsheetUpload from '@/components/SpreadsheetUpload'
import ReportGeneration from '@/components/ReportGeneration'
import SurveyTokenGeneration from '@/components/SurveyTokenGeneration'


let components = {
    RouteView: RouteView,
    GreetingRouteView: GreetingRouteView,
    Home: Home,
    Login: Login,
    Logout: Logout,
    Discharges: Discharges,
    Reports: Reports,
    PasswordReset: PasswordReset,
    Profile: Profile,
    SpreadsheetUpload: SpreadsheetUpload,
    ReportGeneration: ReportGeneration,
    SurveyTokenGeneration: SurveyTokenGeneration,
}

class Route {
    constructor(data) {
        this.parent = null
        Object.assign(this, data)
        this.children = []
        if (this.slug === 'landing') this.slug = ''
        this.path = this.slug
        if (!this.component) {
            this.component = RouteView
        } else {
            this.component = components[this.component]
        }
        if (!this.props) this.props = {}
        this.props.route = this
        if (!this.meta) this.meta = {}
        this.meta.route = this
        if (this.article) {
            if (!this.title) this.title = this.article.title
            if (!this.title) this.title = this.article.slug
            if (!this.subtitle) this.subtitle = this.article.subtitle
            if (!this.description) this.description = this.article.description
        }
    }

    isAvailable(account) {
        if (this.parent && !this.parent.isAvailable(account)) return false
        if (!this.available) return true
        if (this.available === 'logged_out') return !account
        return account && !!account[this.available]
    }

    setup() {
        this.path = this.renderPath()
        this.link = this.renderLink()
    }

    renderLink() {
        let l = ''
        if (this.parent) l = this.parent.renderLink()
        while (l.endsWith('/')) {
            l = l.substr(0, l.length - 1)
        }
        return l + '/' + this.slug
    }

    renderPath() {
        if (!this.parent) return '/' + this.slug
        return this.slug
    }
}


export default Route