import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import fileIcons from './file-icons'
import axios from 'axios'
import {greet} from '@/util'


export default new Vuex.Store({
    state: {
        backendpoint: 'http://localhost:8089',
        apiPath: '/api',
        // apiPath: 'https://gfqg.de/api',
        directoryPrefix: '/Extern',
        idToken: null,
        snackbar: {},
        isManager: false,
        editMode: false,
        sidenavVisible: false,
        appBarLogoVisible: true,
        nav: null,
        currentRoute: null,
        routesById: {},
        navItems: [],
        footerItems: [],
        config: {},
        token: null,
        website: null,
        account: null,
        getColor(item) {
            if (item.mime) {
                if (item.mime === 'application/pdf') return 'red'
                if (item.mime === 'text/markdown') return 'black'
                if (item.mime.includes('spreadsheet') || item.mime.includes('excel')) return 'green'
                if (item.mime.includes('document') || item.mime.includes('word')) return 'blue'
                if (item.mime.includes('presentation') || item.mime.includes('powerpoint')) return '#cb4a32'
            }
            return 'dark-gray'
        },
        fileIcons: fileIcons,
    },
    mutations: {
        showAppBarLogo(state, show = true) {
            state.appBarLogoVisible = show
        },
        hideAppBarLogo(state, show = false) {
            state.appBarLogoVisible = !show
        },
        routesById(state, routesById) {
            state.routesById = routesById
        },
        idToken(state, idToken) {
            state.idToken = idToken
        },
        account(state, account) {
            state.account = account
            state.isManager = account && account.admin !== null
            state.editMode = false
            // eslint-disable-next-line no-undef
            window.umami.enable(!state.isManager)
        },
        editMode(state, editMode) {
            state.editMode = editMode
        },
        nav(state, nav) {
            state.nav = nav
        },
        currentRoute(state, currentRoute) {
            state.currentRoute = currentRoute
        },
        navItems(state, navItems) {
            state.navItems = navItems
        },
        footerItems(state, footerItems) {
            state.footerItems = footerItems
        },
        config(state, config) {
            state.config = config
        },
        message(state, message) {
            state.snackbar = {
                visible: true,
                message: message,
                color: 'info',
            }
        },
        messageSuccess(state, message) {
            state.snackbar = {
                visible: true,
                message: message,
                color: 'success',
            }
        },
        messageError(state, message) {
            state.snackbar = {
                visible: true,
                message: message,
                color: 'error',
            }
        },
        website(state, website) {
            state.website = website
        },
        sidenavVisible(state, visible) {
            state.sidenavVisible = visible
        },
        toggleSidenav(state) {
            state.sidenavVisible = !state.sidenavVisible
        },
    },
    actions: {
        restoreSession(store) {
            return new Promise((resolve, reject) => {
                axios.get(store.state.apiPath + '/auth/?silent=true')
                    .then(response => {
                        if (response.data.hasOwnProperty('id')) {
                            store.dispatch('setAccount', response.data).then(resolve, reject)
                        } else {
                            resolve()
                        }
                    }, reject)})
        },
        login(store, creds) {
            return new Promise((resolve, reject) => {
                axios.post(store.state.apiPath + '/auth/', creds)
                    .then(response => {
                        store.dispatch('setAccount', response.data).then(resolve, reject)
                        // if (response.data.admin !== null) {
                        //     creds.mode = 'cookie'
                        //     axios.post('https://cms2.gfqg.de/auth/login', creds)
                        // }
                    }, error => {
                        if (error.response.status === 401) {
                            store.commit('messageError', '<b>Login fehlgeschlagen</b><br>Ungültige Zugangsdaten')
                        }
                        reject(error)
                    })
            })
        },
        editAccount(store, payload) {
            return new Promise((resolve, reject) => {
                axios.patch(store.state.apiPath + '/auth/account/', payload)
                    .then(response => {
                        store.dispatch('setAccount', response.data).then(resolve, reject)
                    }, reject)
            })
        },
        setAccount(store, account) {
            return new Promise((resolve) => {
                store.commit('account', account)
                resolve()
            })
        },
        logout(store) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(store.state.apiPath + '/auth/')
                    .then(() => {
                        store.commit('message', 'Sie haben sich erfolgreich abgemeldet.')
                        store.commit('account', null)
                        resolve()
                    }, error => {
                        store.commit('messageError', error.response.data.message)
                        reject(error)
                    })
            })
        },
        greeting(store) {
            store.commit('messageSuccess', '<b>Login erfolgreich</b><br>' + greet(store.state.account))
        },

    },
})
