export function resolveValue(value) {
  if (typeof value === 'function') {
    return Promise.resolve(value(...Array.from(arguments).slice(1)))
  } else {
    return Promise.resolve(value)
  }
}

export function parseDate(date) {
  if (typeof date === Number) {
    return '' + date
  }
  return new Date(date).toLocaleDateString('de-DE')
}

export function dateRangeString(from, to) {
  let rangeString = ''
  if (from) {
    rangeString += parseDate(from)
  }
  if (to) {
    rangeString += from ? ' - ' : 'bis '
    rangeString += parseDate(to)
  }
  return rangeString
}

export function relativeDateRangeString(from, to) {
  let rangeString = ''
  if (from) {
    if (!to) {
      rangeString += 'seit '
    }
    rangeString += parseDate(from)
  }
  if (to) {
    rangeString += from ? ' - ' : 'bis '
    rangeString += parseDate(to)
  }
  return rangeString
}

export function applyPrefix(arr, prefix) {
  if (!prefix) {
    return arr.slice()
  }
  return arr.map(f => prefix + '.' + f)
}

export function sortByStringValueDesc(key) {
  return (a, b) => {
    if (a[key] === null && b[key] === null) return 0
    if (a[key] === null) return 1
    if (b[key] === null) return -1
    return a[key].localeCompare(b[key]) * -1
  }
}

export function greet(account) {
  let greeting = ''
  let hours = new Date().getHours();
  if (hours >= 18) {
    greeting = 'Guten Abend'
  } else if (hours >= 11) {
    greeting = 'Guten Tag'
  } else if (hours >= 5) {
    greeting = 'Guten Morgen'
  } else {
    greeting = 'Hallo'
  }
  if (account) {
    greeting += ' ' + account.salutation
  }
  return greeting
}