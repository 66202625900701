import Vue from 'vue'
import vuetify from './plugins/vuetify';

import store from './store'

import App from './App.vue'

import startUmami from './umami'
// eslint-disable-next-line no-undef
startUmami(window)

import axios from 'axios'
import VueAxios from 'vue-axios'
import VueObserveVisibility from 'vue-observe-visibility'
import Vuelidate from 'vuelidate'
import _ from 'lodash';
import VueUploadComponent from 'vue-upload-component'

Object.defineProperty(Vue.prototype, '_', { value: _ });
Vue.component('file-upload', VueUploadComponent)

Vue.use(VueObserveVisibility)
Vue.use(VueAxios, axios)
Vue.use(Vuelidate)

// require styles
import '@mdi/font/css/materialdesignicons.css'
import 'typeface-titillium-web/index.css'
import Pending from '@/components/Pending'
import ArticleView from '@/components/article/ArticleView'

import routerPromise from './router'
Vue.component("ArticleView", ArticleView)
Vue.component("Pending", Pending)

Vue.config.productionTip = false
let vuePromise = new Promise((resolve, reject) => {
  store.dispatch('restoreSession').then(() => {
    routerPromise.then(router => {
        axios.get('/c2/items/website').then(response => {
          store.commit('website', response.data.data)
          resolve(new Vue({
            vuetify,
            router,
            store,
            render: h => h(App),
          }).$mount('#app')
        )
      }, reject)
    }, reject)
  })
})

export default vuePromise

