<template>
  <v-container style="max-width: 1200px;">
    <h1 v-if="$store.state.account">{{ greet($store.state.account) }}</h1>
    <RouteView :route="route" />
  </v-container>
</template>

<script>
import RouteView from '@/components/RouteView'
import {greet} from '@/util'

export default {
  name: 'GreetingRouteView',
  components: {RouteView},
  props: {
    route: [Object],
  },
  methods: {
    greet: greet,
  },
}
</script>
