<template>
  <v-footer dark>
    <v-btn
      v-if="editButton"
      class="m-0"
      tile
      large
      elevation="0"
      @click="$store.commit('editMode', !editMode)"
    >
      <v-icon :color="editButton.color">{{ editButton.icon }}</v-icon>
    </v-btn>
    <v-spacer />
    <v-btn
      v-for="item in footerItems"
      :key="item.id"
      class="m-0"
      tile
      large
      elevation="0"
      :to="item.link"
    >
      <v-icon v-if="item.icon" class="mr-2">{{ item.icon }}</v-icon> {{ item.title }}
    </v-btn>
  </v-footer>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Footer',
  data: () => ({
  }),
  computed: {
    ...mapState(['footerItems', 'isManager', 'editMode']),
    editButton() {
      if (this.isManager) {
        if (this.editMode) return { color: 'red', icon: 'mdi-eye-off' }
        return { color: 'success', icon: 'mdi-eye' }
      }
      return false
    },
  },
};
</script>

<style lang="scss">
</style>
