<template>
  <v-app class="app">
    <AppBar />
    <NavigationDrawer />
    <v-main>
      <v-container>
        <router-view style="min-height: 100%;" />
      </v-container>
      <Message />
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import AppBar from '@/components/AppBar'
import NavigationDrawer from '@/components/NavigationDrawer'
import Footer from '@/components/Footer'
import Message from '@/components/Message'

export default {
  name: 'App',
  components: {
    AppBar,
    NavigationDrawer,
    Footer,
    Message,
  },
};
</script>

<style scoped lang="scss">
.v-application {
  &.theme--light {
    background-color: var(--v-background-base);
    color: #2E2C41;
  }
  .nested-route-view {
    max-width: 1200px;
  }
  .container {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
}
</style>
