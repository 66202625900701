<template>
  <transition mode="out-in">
    <div
      v-if="resolved && !err && !msg"
      key="a"
    >
      <div>{{ msg }}</div>
      <slot :result="result" />
    </div>
    <div
      v-else
      key="b"
      class="pending is-pending pa-6"
      :class="{inline: !inline}"
    >
      <div class="pending-progress" :class="{inline: !inline}">
        <v-progress-circular
          v-if="pending"
          :size="70"
          :width="7"
          indeterminate
        />
        <div>{{ msg }}</div>
        <div>{{ err }}</div>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: 'Pending',
    props: {
        value: [Promise, String, Object, Number, Array, Boolean],
        message: String,
        error: String,
        inline: Boolean,
    },

    data: () => ({
        pending: false,
        resolved: false,
        msg: null,
        err: null,
        result: undefined,
    }),
    watch: {
        value(val, old) {
            this.setValue(val, old)
        },
        message(message) {
            this.msg = message
        },
    },
    mounted() {
        this.msg = this.message
        this.err = this.error
        this.setValue(this.value)
    },
    methods: {
        setValue(val) {
            if (!val) {
                this.resolved = false
                this.pending = false
                this.msg = this.message
                this.err = this.error
            } else if (typeof val === 'string') {
                this.resolved = false
                if (!this.message) this.msg = val
            } else if (val instanceof Promise) {
                this.resolved = false
                this.promise = val
                this.pending = true
                this.result = undefined
                this.msg = null
                this.err = null
                val.then((result) => {
                    if (this.promise === val) {
                        this.result = result
                        this.pending = false
                        this.resolved = true
                    }
                }, err => {
                    if (this.promise === val) {
                        this.pending = false
                        this.resolved = false
                        if (!this.error) this.err = err
                        throw err
                    }
                })
            } else {
                this.result = val
                this.resolved = true
            }
        }
    }
  }
</script>

<style scoped lang="scss">
    .pending {
        width: 100%;
        height: 100%;
    }
    .message {
        width: 100%;
        height: 100%;
    }
    .is-pending {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-content: center;
        &.inline {
            display: inline-flex;
        }
    }
    .pending-progress {
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: center;
        align-content: center;
        &.inline {
            display: inline-flex;
        }
    }
</style>