<template>
  <div
    ref="drag"
    class="file-upload"
    :class="{over: isOver}"
  >
    <v-icon x-large>mdi-table-arrow-up</v-icon>
    <span class="caption">Datei(en) hier ablegen oder klicken um Datei(en) auszuwählen</span>
    <input
      id="spreadsheetUploadFileInput"
      type="file"
      @change="handleChange"
      multiple
    >
  </div>
</template>

<script>
export default {
  name: "FileUploadArea",
  data: () => ({
    file: null,
    files: [],
    isOver: false,
  }),
  mounted() {
     // add the needed event listeners to the container
     this.$refs.drag.addEventListener("dragover", () => {
          this.isOver = true; // add class on drag over
        });
     this.$refs.drag.addEventListener("dragleave", () => {
          this.isOver= false; // remove class on drag leave
        });
  },
  methods: {
    handleDrop(event) {
      Array.from(event.dataTransfer.files).forEach(f => this.$emit('file', f))
    },
    handleChange(event) {
      Array.from(event.target.files).forEach(f => this.$emit('file', f))
    },
  },
}
</script>

<style scoped lang="scss">
.file-upload {
  position: relative;
  width: 100%;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed rgba(0, 0, 0, 0.15);
  cursor: pointer;

  &:hover, &.over {
    background-color: rgba(0, 0, 0, 0.05);
    border-color: rgba(0, 0, 0, 0.20);
  }

  input {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100px;
    opacity: 0;
  }
}
</style>