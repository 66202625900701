<template>
  <!-- <pre>{{ open }}</pre> -->
  <!-- <pre>{{ hierarchy }}</pre> -->
  <v-card class="py-2 px-2">
    <v-card-title class="text-center mb-2">
      <h1>Berichte</h1>
    </v-card-title>
    <v-card-subtitle>Hier finden Sie Ihre Berichte.</v-card-subtitle>

    <FileUploadArea
      v-if="$store.state.isManager"
      @file="handleAutoUploadFile"
    />

    <v-alert
      v-for="(distributedFile, dfi) in distributedFiles"
      :key="dfi"
      dense
      text
      :type="distributedFile.result?.success === true ? 'success' : (distributedFile.result?.success === false ? 'error': null)"
      class="mt-1"
    >
      <div>{{ distributedFile.name }}</div>
      <div v-if="distributedFile.result?.log">
        <v-alert
          v-for="([type, msg], dfli) in distributedFile.result.log"
          :key="dfli"
          dense
          :type="type"
        >
          {{ msg }}
        </v-alert>
      </div>
    </v-alert>

    <Pending
      v-model="rootItemsPromise"
      v-slot="{ result: rootItems }"
    >
      <v-treeview
        dense
        open-on-click
        item-text="label"
        item-key="path"
        :items="rootItems"
        :open.sync="open"
        :active.sync="active"
        activatable
        :load-children="fetchDir"
        @update:active="clickedFile"
      >
        <template v-slot:prepend="{ item, open }">
          <v-icon v-if="item.type === 'dir'" style="color:#f4b459;">
            {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
          </v-icon>
          <v-icon v-else-if="item.type === 'client'" style="color:#59a7f4;">
            {{ open ? 'mdi-folder-open' : 'mdi-folder-home' }}
          </v-icon>
          <v-icon v-else-if="fileIcons[item.mime]" :style="{color: fileIcons[item.mime].color ? fileIcons[item.mime].color : 'dark-grey'}">
            {{ fileIcons[item.mime].icon }}
          </v-icon>
          <v-icon v-else>mdi-file</v-icon>
        </template>
        <template v-slot:append="{ item, leaf }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                small
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item v-if="!leaf && item.permissions.includes('w')" @click="startUploadTo(item)">
                <v-icon left>mdi-upload</v-icon> Dateien in Verzeichnis hochladen
              </v-list-item>
              <v-list-item v-if="!leaf && item.permissions.includes('w')" @click="showCreateDirectoryDialog(item)">
                <v-icon left>mdi-folder-plus</v-icon> Ordner hier erstellen
              </v-list-item>
              <v-list-item @click="download(item)">
                <v-icon left>mdi-download</v-icon> {{ leaf ? 'Datei' : 'Verzeichnis' }} herunterladen
              </v-list-item>
              <v-list-item v-if="item.permissions.includes('d')" @click="ensureDeleteItem(item)">
                <v-icon left>mdi-trash-can</v-icon> {{ leaf ? 'Datei' : 'Verzeichnis' }} Löschen
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-treeview>
    </Pending>

    <v-dialog v-model="deleteDialog" width="500">
      <v-card v-if="toDelete !== null">
        <v-card-title class="text-h5 grey lighten-2">
          {{ toDelete.type === 'file' ? 'Datei' : 'Verzeichnis' }} Löschen
        </v-card-title>
        <v-card-text>
          <p class="mt-6">
            Sind sie Sicher, dass Sie
            {{ toDelete.type === 'file' ? ' diese Datei' : 'dieses Verzeichnis' }}
            löschen möchten?<br>
          </p>
          <p class="mt-6 text-center">
            <b>{{ toDelete.path }}</b>
          </p>
          <p class="mt-6">
            Das ist kann nicht rückgängig gemacht werden.
          </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="deleteDialog = false">Abbrechen</v-btn>
          <v-btn
            color="red"
            dark
            @click="deleteItem"
          >
            {{ toDelete.type === 'file' ? 'Datei' : 'Verzeichnis' }} Löschen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="uploadDialog" width="500">
      <v-card v-if="uploadTo !== null">
        <v-card-title class="text-h5 grey lighten-2">
          Dateien hochladen
        </v-card-title>
        <v-card-text>
          <ul>
            <li v-for="(file, i) in files" :key="i">
              {{ file.name }} - Error: {{ file.error }} - Success: {{ file.success }}
            </li>
          </ul>
          <p class="mt-6">
            <file-upload
              ref="upload"
              v-model="files"
              class="drop-target"
              :drop="true"
              :drop-directory="true"
              :post-action="uploadTo.url"
              :disabled="$refs.upload && $refs.upload.active"
              @input-file="inputFile"
            >
              Dateien und Ordner in <b>{{ uploadTo.path }}</b> ablegen.
            </file-upload>
          </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <button
            v-if="!$refs.upload || !$refs.upload.active"
            type="button"
            @click.prevent="$refs.upload.active = true"
          >
            Start upload
          </button>
          <button
            v-if="$refs.upload && $refs.upload.active"
            type="button"
            @click.prevent="$refs.upload.active = false"
          >
            Stop upload
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="createDirectoryDialog" width="500">
      <v-card v-if="uploadTo !== null">
        <v-card-title class="text-h5 grey lighten-2">
          Ordner erstellen
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="createDirectoryName"
            :disabled="createDirectoryPending"
            label="Ordner Name"
            @keydown.enter="createDirectory()"
            autofocus
          />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click.prevent="createDirectoryName = ''; uploadTo = null; createDirectoryDialog = false"
          >
            Abbrechen
          </v-btn>
          <v-btn
            color="success"
            :disabled="createDirectoryPending || !createDirectoryName"
            @click.prevent="createDirectory()"
          >
            Erstellen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <a
      ref="dlAnchor"
      style="display:none;"
      download
    />
    <div v-if="$store.state.isManager">
      <v-card-title class="text-center mt-8 pb-0">Alle Berichte herunterladen</v-card-title>
      <v-card-text>
        <TimeRangeSelect v-model="globalDownloadTimeRange" />
        <v-btn
          :disabled="!globalDownloadTimeRange"
          @click.prevent="downloadAll(globalDownloadTimeRange)"
          block
        >
          Download
        </v-btn>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import axios from "axios";
import FileUploadArea from "@/components/FileUploadArea.vue";
import TimeRangeSelect from '@/components/TimeRangeSelect.vue'
export default {
  name: 'Reports',
  components: {TimeRangeSelect, FileUploadArea},
  data: () => ({
    selectedPdf: null,
    uploadDialog: false,
    createDirectoryDialog: false,
    createDirectoryName: '',
    createDirectoryPending: false,
    deleteDialog: false,
    toDelete: null,
    uploadTo: null,
    files: [],
    isSaving: false,
    isInitial: false,
    dlSrc: '',
    open: [],
    active: [],
    tree: [],
    hierarchy: [],
    rootItemsPromise: null,
    globalDownloadTimeRange: null,
    itemsByPath: {},
    overlay: false,
    distributedFiles: [],
  }),
  computed: {
    ...mapState(['fileIcons', 'idToken']),
  },
  mounted () {
    this.reload()
  },
  methods: {
    handleAutoUploadFile(jsFile) {
      const distributedFile = {
        name: jsFile.name,
        result: null,
      }
      this.distributedFiles.unshift(distributedFile)
      // eslint-disable-next-line no-undef
      let formData = new FormData()
      formData.append('file', jsFile)
      axios.post(this.$store.state.apiPath + '/files/?replace_existing_older=true&insert_if_newer_exists=false', formData).then(
          result => {
            distributedFile.result = result.data
          },
          error => {
            distributedFile.result = {
              success: false,
              log: [['error', '' + error]]
            }
          },
      )
    },
    inputFile(newFile) {
      newFile.active = true
      if (newFile.xhr && !newFile.listening) {
        newFile.listening = true
        newFile.xhr.addEventListener('readystatechange', (e) => {
          if (newFile.xhr.readyState === 4 && newFile.xhr.status === 200) {
            console.log(newFile.xhr.responseText)
            console.log(e)
            this.parseItem(JSON.parse(newFile.xhr.responseText), this.uploadTo)
          }
        })
      }
    },
    reload() {
      this.rootItemsPromise = new Promise((resolve, reject) => {
        this.axios
            .get(this.$store.state.apiPath + '/files/')
            .then(response => {
              resolve(response.data.map(this.parseItem))
            }, reject)
      })
    },
    parseItem(item, parent) {
      if (item.type !== 'file' && (item.children === null || item.children === undefined)) {
        item.children = []
      } else if (Array.isArray(item.children)) {
        if (item.children.length === 0) {
          item.children = null
        } else {
          item.children = item.children.map(item.children)
        }
      }
      if (parent && typeof parent === 'object') {
        item.parent = parent
        parent.children.push(item)
      } else {
        item.parent = null
      }
      item.path = this.pathOf(item)
      item.url = this.$store.state.apiPath + '/files' + encodeURI(item.path)
      this.itemsByPath[item.path] = item
      return item
    },
    downloadAll(timeRange) {
      this.$refs.dlAnchor.setAttribute('href',  this.$store.state.apiPath + '/files/?download=zip&flat=true&time_range=' + timeRange);
      this.$refs.dlAnchor.click()
    },
    download(item) {
      let dlUrl = item.url
      // eslint-disable-next-line no-undef
      window.umami.trackDownload('/reports' + item.path)
      if (item.type !== 'file') dlUrl += '?download=zip'
      this.$refs.dlAnchor.setAttribute('href', dlUrl);
      this.$refs.dlAnchor.click()
    },
    clickedFile() {
      if (this.active.length > 0) {
        let item = this.itemsByPath[this.active[0]]
        this.active.splice(0, this.active.length)



        if (item.mime === 'application/pdf' || item.mime.startsWith('image/')) {
          // eslint-disable-next-line no-undef
          window.umami.trackDownload('/reports' + item.path)
          // eslint-disable-next-line
          window.open(item.url, '_blank').focus()
        } else {
          this.download(item)
        }
      }
    },
    pathOf(item) {
      let path = item.type === 'file' ? '' : '/'
      while (item) {
        path = '/' + item.slug + path
        if (item.type === 'client') break;
        item = item.parent
      }
      return path
    },
    ensureDeleteItem(item) {
      this.toDelete = item
      this.deleteDialog = true
    },
    startUploadTo(item) {
      this.uploadTo = item
      this.uploadDialog = true
    },
    showCreateDirectoryDialog(item) {
      this.uploadTo = item
      this.createDirectoryName = ''
      this.createDirectoryDialog = true
    },
    createDirectory() {
      this.createDirectoryPending = true
      this.axios
          .post(this.uploadTo.url, {dirname: this.createDirectoryName})
          .then(response => {
            if (this.uploadTo.fetched) {
              this.parseItem(response.data, this.uploadTo)
            }
            this.createDirectoryDialog = false
            this.uploadTo = null
            this.createDirectoryName = ''
          })
          .finally(() => {
            this.createDirectoryPending = false
          })
    },
    deleteItem() {
      let item = this.toDelete
      this.toDelete = null
      this.deleteDialog = false
      this.axios
          .delete(item.url)
          .then(() => {
            if (item.type === 'client') {
              this.reload()
            } else if (item.parent) {
              const index = item.parent.children.indexOf(item);
              if (index > -1) {
                item.parent.children.splice(index, 1);
              }
            } else {
              console.log(this.rootItemsPromise)
            }
          })
    },
    async fetchDir(item) {
      return new Promise((resolve, reject) => {
        this.axios
          .get(item.url)
          .then(response => {
            item.fetched = true
            resolve(response.data.forEach(child => this.parseItem(child, item)))
          }, reject)
      })
    },
  },
};
</script>

<style lang="scss">
.drop-target {
  width: 100%;
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  color: dimgray;
  padding: 10px 10px;
  min-height: 100px; /* minimum height */
  position: relative;
  cursor: pointer !important;
  input {
    cursor: pointer !important;
  }
  &:hover {
    background: rgba(0,0,0,.1); /* when mouse over to the drop zone, change color */
  }
}

.global-download {
  padding: 36px;
}
</style>

