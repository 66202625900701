<template>
  <v-snackbar 
    v-model="snackbar.visible"
    :color="snackbar.color"
    bottom
    right
    multi-line
    class="mb-12"
  >
    <span v-html="snackbar.message"></span>
    <v-btn icon @click.native="snackbar.visible = false" dark>
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </v-snackbar>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Message',
  computed: {
    ...mapState(['snackbar']),
  },
  methods: {

  },
}
</script>