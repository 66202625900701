<template>
  <div class="auth-container">
    <div class="auth-card">
      <h1>Login</h1>
      <v-card class="mt-6">
        <v-card-text>
          <v-form
            ref="form"
            v-model="validation.isValid"
            class="auth-card-content"
            lazy-validation
            @submit.stop="login"
          >
            Hier kommen unsere Kunden zum Internen Bereich. <br>
            Sollten Sie Schwierigkeiten mit dem Zugang haben, wenden Sie sich bitte an info@gfqg.de.

            <v-text-field
              class="mt-6"
              v-model="credentials.email"
              :disabled="pending"
              label="E-Mail"
              prepend-icon="mdi-account-circle"
              :rules="validation.email"
              :error="badCreds"
              required
            />
            <v-text-field
              v-model="credentials.password"
              :type="showPassword ? 'text' : 'password'"
              :disabled="pending"
              label="Passwort"
              :rules="validation.password"
              :error="badCreds"
              required
              prepend-icon="mdi-lock"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
            />
            <v-btn
              class="px-6 mt-8"
              color="primary"
              type="submit"
              :loading="pending"
              :disabled="pending"
            >
              Login
            </v-btn>
          </v-form>
          <div style="display: flex; flex-direction: row-reverse;">
            <router-link to="/passwort">Passwort vergessen?</router-link>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data () {
    return {
      badCreds: false,
      credentials: {
        email: '',
        password: '',
      },
      validation: {
        isValid: true,
        email: [
          v => !!v || 'Bitte E-Mail angeben',
        ],
        password: [
          v => !!v || 'Bitte Passwort angeben',
        ],
      },
      pending: false,
      showPassword: false,
    }
  },
  watch: {
    credentials: {
      handler() {
        this.badCreds = false
      },
      deep: true
    },
  },
  mounted() {
    if (this.$store.state.account !== null) this.$router.replace('/intern')
  },
  methods: {
    login(e) {
      if (e) e.preventDefault()
      this.pending = true
      this.$store.dispatch('login', this.credentials).then(() => {
        this.$store.dispatch('greeting')
        // /intern/asdf?asdf=tet
        this.$router.replace(this.$route.query.redirect || this.$route.query.r || '/intern')
        this.credentials.email = ''
        this.credentials.password = ''
        this.pending = false
      }, () => {
        this.pending = false
      })
    },
  },
}
</script>

<style lang="scss">
.auth-container {
  background-image: url("../assets/lock.svg"); /* The image used */
  // background-color: #cccccc; /* Used if the image is unavailable */
  // height: 500px; /* You must set a specified height */
  background-position: right bottom; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-color: white;
  background-size: 80vh;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  //justify-content: center;
  align-items: center;
  .auth-card {
    max-width: 600px;
    width: 600px;
    .auth-card-content {
      margin-right: auto !important;
      margin-left: auto !important;
      max-width: 400px;
      text-align: center;
      padding: 24px 0;
    }
  }
  .v-stepper__label {
    text-align: center;
  }
}
.login-card {
  background-color: #ffffffcc !important;
}
</style>
