var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"greeter"},[_c('v-container',{staticClass:"mb-12"},[_c('v-row',[_c('v-col',{staticClass:"mt-12",staticStyle:{"display":"flex"},attrs:{"xl":_vm.logosize,"md":_vm.logosize+1,"sm":_vm.logosize+2,"cols":_vm.logosize+3,"offset-xl":"2","offset":"1"}},[_c('v-img',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
              callback: _vm.visibilityChanged,
              intersection: {
                threshold: 0.4,
              },
            }),expression:"{\n              callback: visibilityChanged,\n              intersection: {\n                threshold: 0.4,\n              },\n            }"}],attrs:{"src":require('../assets/gfqg-logo-caption-path-primary.svg'),"contain":"","eager":""}}),_c('v-img',{attrs:{"src":require('../assets/StandwithUkraine2.jpg'),"contain":"","eager":"","height":"180px"}})],1)],1),_c('v-row',{staticClass:"pt-12"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',[_vm._v("Unsere Expertise")])]),_vm._l((_vm.$store.state.routesById['aeaac26f-640c-4f11-8ec2-f97aa7ddf881'].children),function(childRoute){return _c('v-col',{key:childRoute.id,attrs:{"lg":"4","md":"6","cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{attrs:{"height":"100%","to":childRoute.link,"elevation":hover ? 8 : 2}},[_c('v-card-title',[_vm._v(_vm._s(childRoute.title))]),(childRoute.subtitle)?_c('v-card-subtitle',[_vm._v(_vm._s(childRoute.subtitle))]):_vm._e()],1)]}}],null,true)})],1)})],2)],1)],1),_c('div',{staticClass:"greeter-inv"},[_c('v-container',[_c('v-row',{staticClass:"my-12 text-center"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',[_vm._v("Wieso wir?")])]),_vm._l((_vm.website.bulletpoints),function(bulletPoint,index){return _c('v-col',{key:index,attrs:{"md":"4","cols":"12"}},[_c('v-card',{attrs:{"height":"100%","flat":"","to":bulletPoint.link}},[_c('v-card-text',[_c('v-icon',{staticStyle:{"color":"#023465"},attrs:{"x-large":""}},[_vm._v(_vm._s(bulletPoint.icon))])],1),_c('v-card-title',[_c('h3',{staticStyle:{"width":"100%"}},[_vm._v(_vm._s(bulletPoint.title))])]),_c('v-card-text',[_vm._v(" "+_vm._s(bulletPoint.text)+" ")])],1)],1)})],2),_c('Pending',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
          callback: _vm.loadStaff,
          intersection: {
            threshold: 0.4,
          },
        }),expression:"{\n          callback: loadStaff,\n          intersection: {\n            threshold: 0.4,\n          },\n        }"}],attrs:{"value":_vm.staffPromise},scopedSlots:_vm._u([{key:"default",fn:function({ result: staff }){return [_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('h1',[_vm._v("Wer sind wir?")])]),_vm._l((staff),function(member){return _c('v-col',{key:member.id,staticClass:"text-center",attrs:{"md":"3","sm":"6","cols":"12"}},[_c('a',{on:{"click":function($event){_vm.selectedMember = member; _vm.showMemberDialog = true}}},[_c('v-avatar',{staticClass:"mb-4",attrs:{"size":"200","color":member.picture ? '': 'primary'}},[(member.picture)?_c('img',{attrs:{"src":member.picture + '?fit=cover&width=200&height=200&quality=80',"alt":member.name}}):_c('v-icon',{attrs:{"size":100,"color":"white"}},[_vm._v(" mdi-account ")])],1),_c('div',{staticClass:"leader-name"},[_vm._v(_vm._s(member.title)+" "+_vm._s(member.first_name)+" "+_vm._s(member.last_name))]),_c('div',{staticClass:"caption"},[_vm._v(_vm._s(member.role))])],1)])}),_c('v-dialog',{staticStyle:{"position":"relative"},attrs:{"max-width":"700px","scrollable":""},model:{value:(_vm.showMemberDialog),callback:function ($$v) {_vm.showMemberDialog=$$v},expression:"showMemberDialog"}},[(_vm.selectedMember)?_c('v-card',{staticClass:"mx-auto",attrs:{"tile":""}},[_c('v-toolbar',{staticClass:"px-0",attrs:{"dense":"","flat":""}},[_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","tile":""},on:{"click":function($event){_vm.showMemberDialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('PersonView',{attrs:{"person":_vm.selectedMember,"padding":"8","max-height":"90vh"}})],1)],1):_vm._e()],1)],2)]}}])})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }