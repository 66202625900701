<template>
  <v-select
    v-model="select"
    :items="clients"
    :item-text="labelOf"
    item-value="abbr"
    label="Kunde"
    return-object
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
  ></v-select>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ClientSelect',
  props: {
    value: Object
  },
  data: () => ({
    select: null,
    nameAttr: 'name_short',
    clients: [],
  }),
  mounted() {
    axios.get(this.$store.state.apiPath + '/auth/info/clients').then(result => {
      this.addClients(result.data, '')
      // console.log(this.clients)
    })
  },
  methods: {
    addClients(clients, parentStr) {
      clients.sort((a, b) => (a[this.nameAttr] > b[this.nameAttr]) ? 1 : -1)
      clients.forEach(c => {
        c.parentStr = parentStr
        this.clients.push(c)
        this.addClients(c.children, parentStr + c[this.nameAttr] + ' → ')
      })
    },
    labelOf(client) {
      return client.parentStr + client[this.nameAttr]
    },
  },
}
</script>

<style scoped>

</style>