<template>
  <v-container>
    <h1>Mein Profil</h1>
    <v-card class="mt-6">
      <v-banner
        v-if="!!account.admin"
        color="accent"
        dark
      >
        Sie sind Admin. Bitte passen sie Ihren Account
        <a :href="'https://cms2.gfqg.de/admin/users/' + account.id">hier</a>
        im CMS an.
      </v-banner>
      <v-form
        ref="form"
        v-model="validation.isValid"
        class="auth-card-content"
        lazy-validation
        @submit.stop="submit"
      >
        <v-card-text>
          <v-container>
            <h3>Angaben zu Ihrer Person</h3>
            <v-row>
              <v-col cols="12" md="6">
                <v-select
                  v-model="data.gender"
                  :disabled="pending || !!account.admin"
                  :items="[{text: 'Männlich', value:'male'}, {text: 'Weiblich', value:'female'}, {text: 'Divers', value:'diverse'}]"
                  label="Geschlecht"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="data.title"
                  :disabled="pending || !!account.admin"
                  label="Titel"
                  :error="badCreds"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="data.first_name"
                  :disabled="pending || !!account.admin"
                  label="Vorname"
                  :error="badCreds"
                  required
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="data.last_name"
                  :disabled="pending || !!account.admin"
                  label="Nachname"
                  :rules="validation.last_name"
                  :error="badCreds"
                  required
                />
              </v-col>
            </v-row>
            <h3 class="mt-6">Ihre Zugangsdaten</h3>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="data.email"
                  :disabled="pending || !!account.admin"
                  :rules="validation.email"
                  label="E-Mail"
                  prepend-icon="mdi-email"
                  required
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="data.password"
                  :type="showPassword ? 'text' : 'password'"
                  :disabled="pending || !!account.admin"
                  label="Passwort"
                  placeholder="Passwort unverändert lassen"
                  :rules="validation.password"
                  :error="badCreds"
                  required
                  prepend-icon="mdi-lock"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"
                />
              </v-col>
              <v-col cols="12">
                <v-btn
                  block
                  class="px-6 mt-8"
                  color="primary"
                  type="submit"
                  :loading="pending"
                  :disabled="pending || !!account.admin || !validation.isValid"
                >
                  Speichern
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import {mapState} from 'vuex'
import { email } from 'vuelidate/lib/validators'

export default {
  name: 'Profile',
  data () {
    return {
      badCreds: false,
      data: {
        gender: null,
        title: null,
        first_name: null,
        last_name: null,
        email: null,
        password: null,
      },
      validation: {
        isValid: true,
        email: [
          v => (v && email(v)) || 'Bitte gültige E-Mail angeben',
        ],
        last_name: [
          v => !!v || 'Bitte geben Sie Ihren Nachnamen an',
        ],
        password: [
          v => (!v || v.length >= 8) || 'Ihr Passwort muss mindestens 8 Zeichen lang sein',
        ],
      },
      pending: false,
      showPassword: false,
    }
  },
  mounted() {
    this.updateAccount()
  },
  computed: {
    ...mapState(['account']),
  },
  methods: {
    updateAccount() {
      console.log(this.account.admin)
      for (let attr of Object.keys(this.data)) {
        this.data[attr] = this.account[attr]
      }
      this.data.password = null
    },
    submit(e) {
      if (e) e.preventDefault()
      this.pending = true
      let data = { ...this.data }
      if (!data.password) {
        delete data.password
      }
      console.log(data)
      this.$store.dispatch('editAccount', data).then(() => {
        this.updateAccount()
          this.$store.commit('messageSuccess', '<b>Änderungen gespeichert</b>')
      }, error => {
          this.$store.commit('messageError', '<b>Speichern fehlgeschlagen</b>')
          console.log(error)
      }).finally(() => this.pending = false)
    },
  },
}
</script>

<style scoped>

</style>