export default {
    'default': { icon: 'mdi-file' },
    // Media
    'image': { icon: 'mdi-file-image' },
    'audio': { icon: 'mdi-file-music' },
    'audio/mp3': { icon: 'mdi-file-music' },
    'video': { icon: 'mdi-file-video' },
    'video/mp4': { icon: 'mdi-file-video' },
    // Documents
    'application/pdf': { icon: 'mdi-file-pdf', color: 'red' },

    'application/msword': { icon: 'mdi-file-word', color: 'blue' },
    'application/vnd.ms-word': { icon: 'mdi-file-word', color: 'blue' },
    'application/vnd.oasis.opendocument.text': { icon: 'mdi-file-word', color: 'blue' },
    'application/vnd.openxmlformats-officedocument.wordprocessingml': { icon: 'mdi-file-word', color: 'blue' },

    'application/vnd.ms-excel': { icon: 'mdi-file-excel', color: 'green' },
    'application/vnd.openxmlformats-officedocument.spreadsheetml': { icon: 'mdi-file-excel', color: 'green' },
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': { icon: 'mdi-file-excel', color: 'green' },
    'application/vnd.oasis.opendocument.spreadsheet': { icon: 'mdi-file-excel', color: 'green' },

    'application/vnd.ms-powerpoint': { icon: 'mdi-file-powerpoint', color: '#CB4A32' },
    'application/vnd.openxmlformats-officedocument.presentationml': { icon: 'mdi-file-powerpoint', color: '#CB4A32' },
    'application/vnd.oasis.opendocument.presentation': { icon: 'mdi-file-powerpoint', color: '#CB4A32' },

    'text/plain': { icon: 'mdi-file-document-outline' },
    'text/html': { icon: 'mdi-language-html5' },
    'text/markdown': { icon: 'mdi-markdown', color: 'black' },
    'application/json': { icon: 'mdi-json' },
    'application/javascript': { icon: 'mdi-nodejs' },
    // Archives
    'application/gzip': { icon: 'mdi-zip-box' },
    'application/zip': { icon: 'mdi-zip-box' },
}