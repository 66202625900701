import Vue from 'vue'
import Router from 'vue-router'
import axios from 'axios'
import Route from '@/route'
import store from '@/store'
import {applyPrefix} from '@/util'
Vue.use(Router)
import NotFound from '@/components/NotFound'

let routesById = {}
let rootRoutes = []
export let navItems = []
export let footerItems = []
let routeArticleFieldString = applyPrefix(['id', 'slug', 'title', 'subtitle', 'description'], 'article')

let routerPromise = new Promise((resolve, reject) => {
  axios.get('/c2/items/website_route?fields=*,' + routeArticleFieldString.join(',')).then(response => {
    for (let route of response.data.data.map(r => new Route(r))) {
      routesById[route.id] = route
      if (!route.parent) rootRoutes.push(route)
      if (route.nav_item !== null) navItems.push(route)
      if (route.footer_item !== null) footerItems.push(route)
    }

    for (let route of Object.values(routesById)) {
      if (route.parent) {
        route.parent = routesById[route.parent]
        route.parent.children.push(route)
      }
    }

    for (let route of Object.values(routesById)) {
      route.setup()
    }
    for (let route of Object.values(routesById)) {
      route.children.sort((a, b) => a.sort - b.sort)
    }

    navItems.sort((a, b) => a.nav_item - b.nav_item);
    footerItems.sort((a, b) => a.footer_item - b.footer_item);

    store.commit('routesById', routesById)
    store.commit('navItems', navItems)
    store.commit('footerItems', footerItems)

    let router = new Router({
      mode: 'history',
      base: process.env.BASE_URL,
      // scrollBehavior(to, from, savedPosition) {
      //   to
      //   from
      //   savedPosition
      //   return {x: 0, y: 0}
      // },
      routes: [...rootRoutes, {
        path: '/:token',
        // component: TokenIdentifier,
      }, {
        path: '*',
        component: NotFound,
        meta: {
          title: 'Seite nicht gefunden',
        },
      }],
    })

    router.beforeEach((to, from, next) => {
      let toRoute = to.meta.route
      if (!toRoute || toRoute.isAvailable(store.state.account)) {
        store.commit('currentRoute', toRoute)
        next()
      } else if (toRoute.link.startsWith('/intern')) {
        if (toRoute.slug === 'logout') {
          next('/login')
        } else {
          next('/login?r=' + to.fullPath)
        }
      } else {
        next(false)
      }
    })
    resolve(router)
  }, reject)
})

export default routerPromise
