<template>
  <v-card>
    <v-card-text>
      <v-simple-table dense>
        <template v-slot:default>
          <tbody>
            <tr>
              <td>Batch ID</td>
              <td>{{ batch._id }}</td>
            </tr>
            <tr>
              <td>Erzeugt am</td>
              <td>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      {{ batch.created.toLocaleDateString('de') }}
                    </span>
                  </template>
                  <span>{{ batch.created.toLocaleString('de') }}</span>
                </v-tooltip>
              </td>
            </tr>
            <tr>
              <td>Fragebogen</td>
              <td>{{ batch.survey.title }} Tage</td>
            </tr>
            <tr v-if="batch.clients.length === 1">
              <td>Klinik</td>
              <td>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      {{ batch.clients[0].name }}
                    </span>
                  </template>
                  <div>{{ batch.clients[0].name_long }}</div>
                  <div class="caption">{{ batch.clients[0].label }}</div>
                </v-tooltip>
              </td>
            </tr>
            <tr v-else>
              <td>Kliniken</td>
              <td>
                <ul>
                  <li v-for="(client, i) in batch.clients" :key="i">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          {{ client.name }}
                        </span>
                      </template>
                      <div>{{ client.name_long }}</div>
                      <div class="caption">{{ client.label }}</div>
                    </v-tooltip>
                  </li>
                </ul>
              </td>
            </tr>
            <tr v-if="batch.indications.length === 1">
              <td>Indikation</td>
              <td>{{ batch.indications[0].name_long }}</td>
            </tr>
            <tr v-else>
              <td>Indikationen</td>
              <td>
                <ul>
                  <li v-for="(indication, i) in batch.indications" :key="i">{{ indication.name_long }}</li>
                </ul>
              </td>
            </tr>
            <tr v-if="batch.from_month || batch.until_month">
              <td>Entlasszeitpukte</td>
              <td>
                <span v-if="batch.from_month">{{ batch.until_month ? 'von' : 'ab' }} {{ batch.from_month }}</span>
                <span v-if="batch.until_month"> bis {{ batch.until_month }}</span>
              </td>
            </tr>
            <tr>
              <td>Bearbeitungszeit</td>
              <td>{{ batch.access_timespan_days }} Tage</td>
            </tr>
            <tr>
              <td>Anzahl Codes</td>
              <td>{{ batch.count }}</td>
            </tr>
            <tr>
              <td>Aktiv</td>
              <td>{{ batch.disabled ? 'Nein' : 'Ja' }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-menu offset-y>
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            color="primary"
            v-bind="attrs"
            v-on="on"
            class="mt-3"
            block
            small
          >
            Download Codes
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="format in formats"
            :key="format.slug"
            :href="$store.state.apiPath + '/survey/batches/' + batch._id + '/tokens?format=' + format.slug"
            link
          >
            <v-list-item-title>{{ format.label }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'SurveyTokenBatchCard',
  props: {
    batch: Object
  },
  data: () => ({
    formats: [{
      slug: 'pdf',
      label: 'PDF',
    }, {
      slug: 'plain',
      label: 'Klartext',
    }, {
      slug: 'html',
      label: 'HTML',
    }]
  }),
}
</script>

<style scoped>

</style>