<template>
  <div class="person-view">
    <div class="header sticky" :style="{'max-height': headerHeight}">
      <v-row no-gutters>
        <v-col v-if="person.picture" cols="4">
          <v-img
            :src="person.picture + '?fit=inside&width=200&height=' + headerHeight + '&quality=80'"
            :height="headerHeight + 'px'"
            contain
          />
        </v-col>
        <v-col cols="8">
          <v-list>
            <v-list-item three-line class="mb-3">
              <v-list-item-content>
                <div v-if="person.title" class="overline">{{ person.title }}</div>
                <v-list-item-title class="headline mb-1">{{ person.first_name }} {{ person.last_name }}</v-list-item-title>
                <v-list-item-subtitle>{{ person.degree }}</v-list-item-subtitle>
                <v-spacer />
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </div>

    <v-list two-line>
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="primary">mdi-office-building</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Gesellschaft für Qualität im Gesundheitswesen</v-list-item-title>
          <v-list-item-subtitle>
            <span>{{ person.role }}</span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <div class="d-flex flex-row flex-wrap">
      <CardLink v-if="person.phone" class="mr-4 mb-4 py-4 px-6 d-flex flex-row" :href="`tel:${person.phone}`" :elevation="0">
        <v-icon class="mr-6" color="primary">mdi-phone</v-icon>
        <div>
          <div class="subtitle-1">{{ person.phone }}</div>
          <div class="caption">Telefon</div>
        </div>
      </CardLink>
      <CardLink v-if="person.email" class="mr-4 mb-4 py-4 px-6 d-flex flex-row" :href="`mailto:${person.email}`" :elevation="0">
        <v-icon class="mr-6" color="primary">mdi-email</v-icon>
        <div>
          <div class="subtitle-1">{{ person.email }}</div>
          <div class="caption">E-Mail</div>
        </div>
      </CardLink>
    </div>
    <div>
      <div v-if="person.description" class="sticky pb-2" :style="{top: headerHeight + 'px'}">
        <h3>Über {{ person.title }} {{ person.first_name }} {{ person.last_name }}</h3>
      </div>
      <div v-html="person.description" />
    </div>
  </div>
</template>

<script>
import CardLink from '@/components/CardLink'
export default {
  name: 'PersonView',
  components: { CardLink },
  props: {
    person: [Object],
    padding: [String, Number],
  },
  data: () => ({
    headerHeight: 150, // in px
  }),
}
</script>

<style lang="scss">
.link {

}

.sticky {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: white;
  z-index: 10;
  padding-top: 12px;
}
.person-view {
  // position: relative;
  // max-height: 100%;
  // overflow-y: hidden;
  // border: 1px dotted grey;
  .header {
    z-index: 15;
    padding-top: 0;
  }
}



.box .row.content {
  flex: 1 1 auto;
}

.box .row.footer {
  flex: 0 1 40px;
}
</style>