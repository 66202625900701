/* eslint-disable no-unused-vars */
export default function startUmami(window) {
    const {
        screen: {width, height},
        navigator: {language},
        location,
        document,
        history,
    } = window;
    const {hostname, pathname, search} = location;


    const assign = (a, b) => {
        Object.keys(b).forEach(key => {
            if (b[key] !== undefined) a[key] = b[key];
        });
        return a;
    };

    const hook = (_this, method, callback) => {
        const orig = _this[method];
        return (...args) => {
            callback.apply(null, args);
            return orig.apply(_this, args);
        };
    };

    const website = 'bd518819-b556-4022-ae8f-08d8b224bc29';
    const endpoint = '/stats';
    const screen = `${width}x${height}`;

    let currentUrl = `${pathname}${search}`;
    let subUrl = '';
    let currentRef = document.referrer;
    let cache;

    /* Collect metrics */

    function getCurrentUrl(){
        return subUrl ? `${currentUrl} (${subUrl})` : currentUrl
    }
    function getPayload(){
        return {
            website,
            hostname,
            screen,
            language,
            url: getCurrentUrl(),
        }
    }

    let enabled = true;

    function collect(type, payload) {
        if (enabled) {
            // console.log(payload)
            // eslint-disable-next-line no-undef
            fetch(endpoint, {
                method: 'POST',
                body: JSON.stringify({type, payload}),
                headers: assign({'Content-Type': 'application/json'}, {['x-umami-cache']: cache}),
            })
                .then(res => res.text())
                .then(text => (cache = text));
        }
    }

    function trackView() {
        collect('pageview',
            assign(getPayload(), {
                referrer: currentRef,
            }),
        )
    }

    function trackEvent(eventName, eventData) {
        collect('event',
            assign(getPayload(), {
                event_name: eventName,
            }),
        )
    }

    function trackDownload(path) {
        collect('pageview',
            assign(getPayload(), {
                referrer: getCurrentUrl(),
                url: '/_dl_' + path,
            }),
        )
    }

    function setUrl(url) {
        if (url !== currentUrl) {
            currentRef = getCurrentUrl()
            currentUrl = url
            subUrl = undefined;
            trackView();
        }
    }

    function setSubUrl(newSubUrl) {
        if (!newSubUrl) newSubUrl = undefined
        if (newSubUrl !== subUrl) {
            currentRef = getCurrentUrl()
            subUrl = newSubUrl
            trackView()
        }
    }

    /* Handle history changes */

    function handlePush(state, title, url) {
        if (url) {
            const newUrl = url.toString();
            if (newUrl.startsWith('http')) {
                setUrl('/' + newUrl.split('/').splice(3).join('/'))
            } else {
                setUrl(newUrl)
            }
        }
    }

    /* Global */

    if (!window.umami) {
        const umami = eventValue => trackEvent(eventValue)
        umami.enable = (e = true) => {enabled = e}
        umami.trackView = trackView
        umami.trackEvent = trackEvent
        umami.trackDownload = trackDownload
        umami.trackSubView = setSubUrl

        window.umami = umami
    }

    /* Start */
    history.pushState = hook(history, 'pushState', handlePush)
    history.replaceState = hook(history, 'replaceState', handlePush)

    function update() {
        if (document.readyState === 'complete') {
            trackView();
        }
    }

    document.addEventListener('readystatechange', update, true)
    update()
}