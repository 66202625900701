import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import de from 'vuetify/es5/locale/de';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { de },
    current: 'de',
  },
  theme: {
    themes: {
      light: {
        // backgroud: '#ffffff',
        primary: '#023465',
        secondary: '#c9d002',
        accent: '#8c9eff',
        error: '#c52233',
        color: '#c52233',
      },
    },
  },
});
