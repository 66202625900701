<template>
  <div class="auth-container">
    <div class="auth-card">
      <h1>Neues Passwort vergeben</h1>
      <v-stepper
        v-model="stage"
        alt-labels
        class="mt-6"
      >
        <v-stepper-header>
          <v-stepper-step :complete="stage > 1" step="1">
            Rücksetzung anfordern
          </v-stepper-step>
          <v-divider />
          <v-stepper-step :complete="stage > 2" step="2">
            Identität bestätigen
          </v-stepper-step>
          <v-divider />
          <v-stepper-step step="3">
            Neues Passwort vergeben
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <div class="auth-card-content">
              Geben Sie bitte Ihre E-Mail-Adresse an, um Ihr Passwort zurückzusetzen.
              <v-form
                ref="form"
                @submit.stop="request"
              >
                <v-text-field
                  v-model="email"
                  class="password-reset-input"
                  :disabled="pending"
                  :error-messages="$v.email.$invalid && email ? 'Bitte gültige E-Mail angeben' : null"
                  label="E-Mail"
                  prepend-icon="mdi-account-circle"
                />
                <v-btn
                  class="px-6 mt-2"
                  color="primary"
                  type="submit"
                  :loading="pending"
                  :disabled="pending || $v.email.$invalid"
                >
                  Rücksetzung anfordern
                </v-btn>
              </v-form>
            </div>
            <div style="display: flex; flex-direction: row-reverse;">
              <router-link :to="{query: {s: 'authentifikation'}}">Code bereits erhalten? →</router-link>
            </div>
          </v-stepper-content>

          <v-stepper-content step="2">
            <div class="auth-card-content">
              Bitte geben Sie hier den Code ein, den wir Ihnen per E-Mail gesendet haben oder klicken Sie auf den Link in der E-Mail.
              <v-form
                ref="form"
                @submit.stop="confirmToken"
              >
                <v-text-field
                  v-model="token"
                  class="password-reset-input"
                  :disabled="pending"
                  :error-messages="tokenError"
                  label="Code"
                  prepend-icon="mdi-key"
                />
                <v-btn
                  class="px-6 mt-2"
                  color="primary"
                  type="submit"
                  :loading="pending"
                  :disabled="pending || $v.token.$invalid"
                >
                  Identität bestätigen
                </v-btn>
              </v-form>
            </div>
            <router-link :to="{query: {}}">← Code erneut anfordern</router-link>
          </v-stepper-content>

          <v-stepper-content step="3" class="auth-card-content">
            {{ greet(account) }},<br>
            Sie können nun ein neues Passwort vergeben.
            <v-form
              ref="form"
              @submit.stop="setPassword"
            >
              <v-text-field
                v-model="password"
                :error-messages="$v.password.$invalid && $v.password.$dirty ? 'Bitte Passwort eingeben' : null"
                class="password-reset-input"
                :type="showPassword ? 'text' : 'password'"
                :disabled="pending"
                label="Passwort"
                prepend-icon="mdi-lock"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
              />
              <v-btn
                class="px-6 mt-2"
                color="primary"
                type="submit"
                :loading="pending"
                :disabled="pending || $v.password.$invalid"
              >
                Neues Passwort setzen
              </v-btn>
            </v-form>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import axios from 'axios'
import {greet} from '@/util'
export default {
  name: 'PasswordReset',
  validations: {
    email: { required, email },
    token: { required },
    password: { required },
  },
  data () {
    return {
      account: null,
      token: '',
      email: '',
      password: '',
      pending: false,
      isValid: false,
      tokenConfirmed: false,
      tokenError: null,
      showPassword: false,
    }
  },

  computed: {
    stage() {
      if (this.$route.query.hasOwnProperty('t') || this.$route.query.s === 'authentifikation') {
        return 2
      } else if (this.$route.query.s === 'passwortvergabe') {
        return 3
      } else {
        return 1
      }
    }
  },
  mounted() {
    if (this.$route.query.hasOwnProperty('t')) {
      this.token = this.$route.query.t
      this.$router.replace({query:{s: 'authentifikation'}})
      this.confirmToken()
    } else if (this.$route.query.s === 'passwortvergabe') {
      this.$router.replace({query:{}})
    }
  },
  methods: {
    greet: greet,
    request(e) {
      if (e) e.preventDefault()
      this.$v.email.$touch()
      this.pending = true
      axios.post(this.$store.state.apiPath + '/auth/password_reset/', {email: this.email}).then(
          () => {
            this.pending = false
            this.$router.push({query:{s: 'authentifikation'}})
          }, () => {
            this.pending = false
            this.$store.commit('messageError', '<b>Anfrage fehlgeschlagen</b><br>Server Fehler')
          }
      )
    },
    confirmToken(e) {
      if (e) e.preventDefault()
      this.$v.token.$touch()
      this.pending = true
      axios.post(this.$store.state.apiPath + '/auth/password_reset/', {token: this.token}).then(
          response => {
            this.pending = false
            this.account = response.data
            this.$router.push({query:{s: 'passwortvergabe'}})
          }, error => {
            this.pending = false
            let m = error.response.status === 401 ? 'Ungültiger Code' : 'Server Fehler'
            if (error.response.status === 400) {
            const msgs = {
              'already claimed': 'Dieser Code wurde bereits benutzt',
              'timed out': 'Dieser Code ist leider nicht mehr gültig',
              'invalid': 'Dieser Code ist leider ungültig',
            }
            this.tokenError = msgs[error.response.data.message]
            } else {
              this.$store.commit('messageError', '<b>Anfrage fehlgeschlagen</b><br>' + m)
            }
          }
      )
    },
    setPassword(e) {
      if (e) e.preventDefault()
      this.$v.token.$touch()
      this.pending = true
      axios.post(this.$store.state.apiPath + '/auth/password_reset/', {token: this.token, password: this.password}).then(
          response => {
            this.pending = false
            this.password = ''
            this.$store.commit('messageSuccess', '<b>Passwort Rücksetzung gerfolgreich</b><br>Ab jetzt können Sie sich mit Ihrem neuen Passwort anmelden.')
            this.$store.dispatch('setAccount', response.data).then(() => {
              this.$router.push('intern')
            })
          }, error => {
            this.pending = false
            const m = 400 <= error.response.status < 500 ? 'Ungültiger Code' : 'Server Fehler'
            this.$store.commit('messageError', '<b>Anfrage fehlgeschlagen</b><br>' + m)
          }
      )
    },
  }
}
</script>

<style lang="scss">
.password-reset-input {
  margin-top: 24px;
  margin-bottom: 24px;
}
</style>