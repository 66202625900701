<template>
  <v-app-bar
    app
    elevate-on-scroll
    class="py-0"
  >
    <v-app-bar-nav-icon
      v-if="!showNav"
      class="ml-2"
      @click="$store.commit('toggleSidenav')"
    />

    <router-link
      to="/"
      class="py-2 align-left"
      style="height:100%"
    >
      <div style="width: 216px" />

      <v-slide-y-transition>
        <div
          v-if="appBarLogoVisible"
          style="display: flex;"
        >
          <v-img
            class="mx-2 py-2"
            :src="require('@/assets/gfqg-logo-primary.svg')"
            transition="slide-y-transition"
            max-width="200px"
            max-height="100%"
            height="48px"
            contain
            left
            eager
          />
          <v-img
            :src="require('../assets/StandwithUkraine2.jpg')"
            transition="slide-y-transition"
            contain
            eager
            max-width="50px"
            height="48px"
            left
          />
        </div>
      </v-slide-y-transition>
    </router-link>

    <v-spacer />
    <div v-if="showNav" class="nav-container">
      <div
        v-for="(navItem, i) in navItems.filter(i => i.isAvailable(account))"
        :key="navItem.id"
        class="nav-item-container"
      >
        <v-menu
          v-if="navItem.children && navItem.children.length > 0"
          transition="slide-y-transition"
          bottom
          open-on-hover
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-btn
              :to="navItem.link"
              depressed
              tile
              height="100%"
              color="transparent"
              v-on="on"
            >
              <v-icon v-if="navItem.icon" class="mr-2">
                {{ navItem.icon }}
              </v-icon>
              {{ navItem.title }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(child, ci) in navItem.children.filter(c => c.isAvailable(account))"
              :key="ci"
              :to="child.link"
            >
              <v-list-item-title>
                <v-icon v-if="child.icon" class="mr-2">
                  {{ child.icon }}
                </v-icon>
                {{ child.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn
          v-else
          :to="navItem.link"
          depressed
          tile
          height="100%"
          color="transparent"
        >
          <v-icon v-if="navItem.icon" class="mr-2">
            {{ navItem.icon }}
          </v-icon>
          {{ navItem.title }}
        </v-btn>

        <v-divider
          v-if="i + 1 < navItems.length"
          vertical
          class="nav-item-divider"
        />
      </div>
    </div>
  </v-app-bar>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'AppBar',
  props: [],
  computed: {
    ...mapState(['appBarLogoVisible', 'account', 'navItems']),
    showNav() {
      return this.$vuetify.breakpoint.mdAndUp
    },
  }
}
</script>

<style lang="scss">
.logocontainer {
  display: inline-block;
}

.v-app-bar {
  .v-toolbar__content {
    // background-color: #ffffffcc !important;
    padding: 0px;
  }
}

.nav-container {
  // background-color: rgb(245, 238, 238) !important;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100%;
  align-items: stretch;
  .nav-item-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
  }
}

.v-app-bar {
  background-color: transparent !important;
  &.v-app-bar--is-scrolled {
      background-color: white !important;
  }
}

</style>
