<template>
  <div>
    <div v-if="header">
      <h1>{{ article.title }}</h1>
      <h2 v-if="article.subtitle">{{ article.subtitle }}</h2>
    </div>
    <div v-if="article.description" class="mt-6">{{ article.description }}</div>
    <div v-if="article.persons" class="d-flex mb-4 flex-wrap justify-space-around">
      <div
        v-for="(person, pi) in article.persons"
        :key="pi"
        class="ml-6 my-3 d-flex"
      >
        <v-icon class="mr-1">mdi-account-tie</v-icon>
        <div class="d-flex flex-column justify-center" style="line-height: 110%;">
          <div style="font-size: 80%; min-height: 1.25em">{{ person.role }}</div>
          <div>
            {{ person.name }}
          </div>
          <div class="text-right" style="font-size: 80%; min-height: 1.25em">
            {{ person.institutions.join(' | ') }}
          </div>
        </div>
      </div>
    </div>
    <v-card
      v-if="article.infobox"
      class="mb-8"
      tile
    >
      <v-card-text v-html="article.infobox" />
    </v-card>
    <div v-if="article.keywords && article.keywords.length > 0">
      <v-chip
        v-for="(keyword, keywordIndex) in article.keywords"
        :key="keywordIndex"
        class="mr-2 mt-2"
        small
      >
        <v-icon class="mr-2" small>mdi-tag</v-icon>
        {{ keyword }}
      </v-chip>
    </div>
    <div
      v-if="article.content"
      class="mt-6"
      v-html="article.content"
    />
    <div v-if="article.downloads && article.downloads.length > 0">
      <h4>Downloads:</h4>
      <div>
        <v-btn
          v-for="(download, di) in article.downloads"
          :key="di"
          class="ma-2 px-3"
          tile
          large
          :href="download.link"
          target="_blank"
          @click="() => {trackDownload(download)}"
        >
          <v-icon
            v-if="download.icon"
            class="mr-2"
            :color="download.icon.color"
          >
            {{ download.icon.icon }}
          </v-icon>
          {{ download.title }}
        </v-btn>
      </div>
    </div>
    <v-expansion-panels v-if="article.chapters && article.chapters.length > 0" class="mt-8">
      <v-expansion-panel v-for="(chapter, ci) in article.chapters" :key="ci">
        <v-expansion-panel-header>
          <div>
            <h3>{{ chapter.title }}</h3>
            <div v-if="chapter.subtitle">{{ chapter.subtitle }}</div>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div v-html="chapter.content" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import Article from '../../article'

export default {
  name: 'FullArticleView',
  props: {
    article: Article,
    header: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    expansionState: undefined,
  }),
  methods: {
    trackDownload(file) {
      // eslint-disable-next-line no-undef
      window.umami.trackDownload('/' + file.filename_download)
    },
  },
}
</script>

<style lang="scss">
.child-tabs {
  border-bottom: 1px solid lightgray;
}
img {
  max-width: 100%;
}
</style>