<template>
  <Pending v-model="articlePromise" v-slot="{ result: article }">
    <div :class="classes" style="position: relative;">
      <v-btn
        v-if="editMode"
        class="edit-btn"
        :href="'https://cms2.gfqg.de/admin/content/article/' + article.id"
        target="_blank"
        tile
        color="purple"
      >
        <v-icon>mdi-pencil</v-icon>
        Artikel
      </v-btn>
      <FullArticleView
        v-if="display === 'full'"
        :article="article"
        :header="header"
      />
    </div>
  </Pending>
</template>

<script>
import {mapState} from 'vuex'
import Article from '../../article'
import Pending from '../Pending.vue'
import FullArticleView from './FullArticleView'

export default {
  name: 'ArticleView',
  components: {FullArticleView, Pending},
  props: {
    id: {
      type: String,
      default: null,
    },
    slug: {
      type: String,
      default: null,
    },
    article: {
      type: [Promise, Article],
      default: null,
    },
    padding: {
      type: Number,
      default: 8,
    },
    display: {
      type: String,
      default: 'full',
    },
    header: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    articlePromise: null,
    defaultProps: {

    },
  }),
  computed: {
    ...mapState(['editMode']),
    classes() {
      let c = ['pa-' + this.padding]
      if (this.editMode) c.push('article-edit')
      return c
    },
  },
  watch: {
    id() {
      this.articlePromise = this.fetchArticle()
    },
    slug() {
      this.articlePromise = this.fetchArticle()
    },
    article() {
      this.articlePromise = this.fetchArticle()
    },
  },
  mounted() {
    this.articlePromise = this.fetchArticle()
  },
  methods: {
    fetchArticle() {
      if (this.article != null) return Promise.resolve(this.article)
      if (this.id) return Article.byId(this.id)
      if (this.slug) return Article.bySlug(this.slug)
      return Promise.reject('no article provided')
    },
  },
}
</script>
<style scoped lang="scss">
.article-edit {
  border: 1px solid purple;
  background-color: rgba(128, 0, 128, 0.2);
}
.edit-btn {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
