<template>
  <div>
    <v-select
      v-model="reportType"
      :items="reportTypes"
      item-text="label"
      item-value="name"
      label="Art des Berichts"
    />
    <ClientSelect v-if="reportType !== null && reportType !== 'overview'" v-model="client" />
    <TimeRangeSelect v-if="reportType !== null" v-model="timeRange" />
    <v-select
      v-if="reportType === 'benchmark'"
      v-model="orientation"
      :items="orientations"
      item-text="label"
      item-value="name"
      label="Layout"
    />
    <v-select
      v-if="reportType === 'benchmark' && client && client.slug === 'drv-benchmark'"
      v-model="indicationType"
      :items="['som', 'psy']"
      label="Indikation"
    />
    <v-btn
      :disabled="!reportType || !timeRange"
      elevation="2"
      tile
      @click="render"
    >
      Erzeugen
    </v-btn>
    <a
      ref="dlAnchor"
      style="display:none;"
      download
    />

    <v-card
      v-for="rendering in renderings"
      :key="rendering.id"
      :loading="!rendering.duration"
      class="mx-auto my-6"
      :color="rendering.successful === false ? 'error' : undefined"
      :dark="rendering.successful === false"
    >
      <template slot="progress">
        <v-progress-linear
          :indeterminate="!rendering.progress"
          :value="rendering.progress * 100"
        />
      </template>
      <div class="d-flex">
        <v-card-text>
          <v-icon v-if="rendering.successful === false" class="mr-3">mdi-alert</v-icon>
          {{ rendering.filename || rendering._id }}
        </v-card-text>
        <v-card-actions v-if="rendering.duration">
          <v-btn
            v-if="rendering.successful"
            icon
            color="success"
            download
            :href="$store.state.apiPath + '/reports/' + rendering._id + '/data'"
          >
            <v-icon>mdi-download</v-icon>
          </v-btn>
          <v-btn
            v-if="rendering.successful"
            color="error"
            icon
            @click="del(rendering)"
          >
            <v-icon>mdi-trash-can</v-icon>
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
    <a
      ref="dlAnchor"
      style="display:none;"
    />
  </div>
</template>

<script>
import axios from 'axios'
import ClientSelect from '@/components/ClientSelect'
import TimeRangeSelect from '@/components/TimeRangeSelect'


export default {
  name: 'ReportGeneration',
  components: {ClientSelect, TimeRangeSelect},

  data: () => ({
    renderings: null,
    client: null,
    reportType: null,
    timeRange: null,
    interval: null,
    reportTypes: [{
      label: 'Einzelberichte',
      name: 'reports',
    }, {
      label: 'Benchmark',
      name: 'benchmark',
    }, {
      label: 'Übersicht',
      name: 'overview',
    }],
    orientation: 'QUERY_COLUMNS',
    indicationType: 'som',
    orientations: [{
      label: 'Items in Spalten',
      name: 'QUERY_COLUMNS',
    }, {
      label: 'Items in Zeilen',
      name: 'QUERY_ROWS',
    }],
  }),
  mounted() {
    this.loadRenderings()
    this.interval = setInterval(this.loadRenderings, 30000)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {
    loadRenderings() {
      axios.get(this.$store.state.apiPath + '/reports/').then(result => {
        this.renderings = result.data
      })
    },
    del(rendering) {
      axios.delete(this.$store.state.apiPath + '/reports/'+ rendering._id).then(() => {
        this.loadRenderings()
      })
    },
    setClient(e) {
      console.log(e)
      console.log(this.client)
      console.log(this.timeRange)
    },
    render() {
      this.$refs.dlAnchor.click()
      axios.post(this.$store.state.apiPath + '/reports/',  {
        report_type: this.reportType,
        time_range: this.timeRange,
        indication_type: this.indicationType,
        client: this.client ? this.client.id : null,
      }).then(() => {
        this.loadRenderings()
      })
    }
  },
}
</script>

<style scoped>
</style>