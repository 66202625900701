<template>
  <v-hover v-slot:default="{ hover }">
      <v-card :href="href" :elevation="hover ? computedElevation + 4 : computedElevation">
          <slot />
      </v-card>
  </v-hover>
</template>

<script>
export default {
  name: 'CardLink',
  props: {
    href: String,
    elevation: [Number, String],
  },
  computed: {
    computedElevation() {
      if (this.elevation === undefined) return 2
      if (typeof this.elevation === 'string') return Number.parseInt(this.elevation)
      return this.elevation
    },
  },
  methods: {

  },
  data: () => ({

  }),
};
</script>