<template>
  <v-expansion-panels
    v-model="opened"
    class="mt-8"
    @change="track"
  >
    <v-expansion-panel
      v-for="article in articles"
      :key="article.id"
      :ref="article.id"
      style="scroll-margin-top: 64px;"
    >
      <v-expansion-panel-header>
        <div>
          <h3>
            {{ article.title }}
            <small class="ml-2"> {{ dateRangeString(article.date_start, article.date_end) }}</small>
          </h3>
          <div v-if="article.subtitle" class="mt-2 mb-2"><i>{{ article.subtitle }}</i></div>
          <div v-if="article.description" class="mt-1">{{ article.description }}</div>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <ArticleView :article="article" :header="false" :padding="0" />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import {dateRangeString} from '@/util'

export default {
  name: 'ArticleExpansionPanels',
  props: {
    articles: [Array],
  },
  data: () => ({
    opened: undefined,
  }),
  mounted() {
    // eslint-disable-next-line no-undef
    let fragment = window.location.hash
    if (fragment.length > 1) {
      fragment = fragment.substring(1)
      for (let i = 0; i < this.articles.length; i++) {
        const article = this.articles[i]
        if (article.id === fragment || article.slug === fragment) {
          this.opened = i
          setTimeout(() => this.$refs[article.id][0].$el.scrollIntoView(), 500)
        }
      }
    }
  },
  methods: {
    dateRangeString: dateRangeString,
    track(opened) {
      if (opened !== undefined) {
        const article = this.articles[opened]
        if (article.slug) {
          // eslint-disable-next-line no-undef
          history.replaceState('', document.title, window.location.pathname + window.location.search + '#' + article.slug)
        }
        // eslint-disable-next-line no-undef
        window.umami.trackSubView(article.title)
      } else {
        // eslint-disable-next-line no-undef
        window.umami.trackSubView(undefined)
        // eslint-disable-next-line no-undef
        history.replaceState('', document.title, window.location.pathname + window.location.search)
        // window.location.replace(window.location.pathname + window.location.search)
        // eslint-disable-next-line no-undef
        // console.log(window.location)
      }
    },
  },
}
</script>

<style scoped>

</style>