<template>
  <v-navigation-drawer 
    v-model="show"
    app
    temporary
  >
    <v-list-item three-line>
      <v-list-item-content>
        <v-img
          :src="require('../assets/gfqg-logo-primary.svg')"
          contain
          class="px-4 mt-2"
        />
        <v-list-item-subtitle class="text-center">
          Gesellschaft für Qualität im Gesundheitswesen
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider />

    <v-list
      dense
      nav
    >
      <v-list-item
        v-for="navItem in navItems.filter(i => i.isAvailable(account))"
        :key="navItem.id"
        link
        :to="navItem.link"
      >
        <v-list-item-content>
          <v-list-item-title>{{ navItem.title }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon v-if="navItem.icon">
          <v-icon>{{ navItem.icon }}</v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
    <v-divider />

    <v-list dense nav>
      <v-list-item
        v-for="item in footerItems"
        :key="item.id"
        link
        :to="item.link"
      >
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon v-if="item.icon">
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list>

  </v-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'NavigationDrawer',
  data: () => ({
    show: false,
  }),
  computed: {
    ...mapState(['sidenavVisible', 'navItems', 'account', 'footerItems']),
    mobileMode() {
      return !this.$vuetify.breakpoint.mdAndUp
    },
  },
  watch: {
    mobileMode(active) {
      if (!active) {
        this.show = false
      }
    },
    show(visible) {
      this.$store.commit('sidenavVisible', visible);
    },
    sidenavVisible(visible) {
      this.show = visible
    },
  },
};
</script>
