<template>
  <router-view v-if="route.child_display === 'replace' && !isCurrentRoute" />
  <v-container
    v-else
    class="nested-route-view"
  >
    <ArticleView
      v-if="route.article"
      :id="route.article.id"
      :padding="0"
    />
    <Pending
      v-if="articleResolvePromise !== null"
      v-slot="{ result: articles }"
      :value="articleResolvePromise"
    >
      <ArticleExpansionPanels :articles="articles" />
    </Pending>
    <v-row v-if="route.children && route.children.length > 0 && route.child_display === 'tabs' && !isCurrentRoute">
      <v-tabs
        background-color="transparent"
        grow
        class="child-tabs"
      >
        <v-tab
          v-for="(childRoute, childIndex) in route.children.filter(c => c.isAvailable(account))"
          :key="childIndex"
          :to="childRoute.link"
          tile
        >
          <span>{{ childRoute.title }}</span>
        </v-tab>
      </v-tabs>

      <router-view />
    </v-row>
    <v-row v-if="route.children && route.children.length > 0 && isCurrentRoute">
      <v-col
        v-for="childRoute in route.children.filter(c => c.isAvailable(account))"
        :key="childRoute.id"
        lg="4"
        md="6"
        cols="12"
      >
        <v-hover v-slot:default="{ hover }">
          <v-card
            height="100%"
            :to="childRoute.link"
            :elevation="hover ? 8 : 2"
          >
            <v-card-title>
              <v-icon v-if="childRoute.icon" class="mr-4">{{ childRoute.icon }}</v-icon>
              {{ childRoute.title }}
            </v-card-title>
            <v-card-subtitle v-if="childRoute.subtitle">{{ childRoute.subtitle }}</v-card-subtitle>
            <v-card-text v-if="childRoute.description">{{ childRoute.description }}</v-card-text>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <v-row v-if="route.children && route.children.length > 0 && route.child_display === 'menu' && !isCurrentRoute">
      <v-col md="3" cols="12">
        <div class="nested-route-nav pr-4 nested-route-nav-items">
          <v-list
            nav
            class=""
          >
            <v-list-item
              v-for="(childRoute, i) in route.children.filter(c => c.isAvailable(account))"
              :key="i"
              link
              :to="childRoute.link"
            >
              <v-list-item-content>
                <v-list-item-title>{{ childRoute.title }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon v-if="childRoute.icon">
                <v-icon>{{ childRoute.icon }}</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </div>
      </v-col>
      <v-col md="9" cols="12">
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import Article from '@/article'
import ArticleExpansionPanels from '@/components/article/ArticleExpansionPanels'

export default {
  name: 'RouteView',
  components: {ArticleExpansionPanels},
  props: {
    route: [Object],
  },
  data: () => ({
    articleResolvePromise: null,
  }),
  computed: {
    ...mapState(['currentRoute', 'account']),
    isCurrentRoute() {
      return this.route === this.currentRoute
    },
  },
  watch: {
    route(route) {
      this.setup(route)
    },
  },
  mounted() {
    this.setup(this.route)
  },
  methods: {
    setup(route) {
      if (route.resolve_articles) {
        this.articleResolvePromise = Article.findByKeyword(route.resolve_articles, route.sort_articles)
      } else {
        this.articleResolvePromise = null
      }
    },
  },
}
</script>

<style scoped>
.nested-route-view {
  max-width: 1200px;
}
.nested-route-nav {
  position: sticky;
  top: 64px;
}
.nested-route-nav-items {
  border-right: 1px solid lightgray;
}
</style>