import fileIcons from './file-icons'

import axios from 'axios'
import {applyPrefix, sortByStringValueDesc} from './util'

function parseFile(file) {
    if (file) {
        file.link = '/c2/assets/' + file.id
        if (file.type && fileIcons[file.type]) {
            file.icon = fileIcons[file.type]
        } else {
            file.icon = fileIcons.default
        }
        return file
    }
}

class Article {
    constructor(data) {
        Object.assign(this, data)
        this.downloads = this.downloads.map(d => parseFile(d.directus_files_id))
    }

    static resolve(data) {
        if (typeof data === 'string') {
            return Article.byId(data)
        }
        return Promise.resolve(new Article(data))
    }

    static byId(id) {
        return new Promise((resolve, reject) => {
            axios.get('/c2/items/article/' + id + '?fields=' + Article.fields().join(',')).then(response => {
                resolve(new Article(response.data.data))
            }, reject)
        })
    }

    static bySlug(slug) {
        return new Promise((resolve, reject) => {
            axios.get('/c2/items/article?fields=' + Article.fields().join(',') + '&filter[slug][_eq]=' + slug).then(response => {
                if (response.data.data.length === 1) {
                    resolve(new Article(response.data.data[0]))
                } else {
                    reject('Artikel nicht gefunden')
                }
            }, reject)
        })
    }

    static findByKeyword(keyword, sort_by) {
        return new Promise((resolve, reject) => {
            axios.get('/c2/items/article?fields=' + Article.fields().join(',') + '&filter[keywords][_contains]=' + keyword).then(response => {
                let articles = response.data.data.map(a => new Article(a))
                articles.sort((a, b) => a.title.localeCompare(b.title))
                articles.sort(sortByStringValueDesc('date_start'))
                articles.sort(sortByStringValueDesc('date_end'))
                if (sort_by) {
                    articles.sort(sortByStringValueDesc(sort_by))
                }
                resolve(articles)
            }, reject)
        })
    }

    static raw_fields = [
        '*',
        'chapters.*',
        'downloads.directus_files_id.*',
    ]

    static fields(base) {
        return applyPrefix(Article.raw_fields, base)
    }


}

export default Article
